import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import { ParallaxProvider } from "react-scroll-parallax";
import Appdata from "./components/Common/Appdata";
import { APP_ROOT } from "./config/config";
//import axios from "axios";

function App() {
  // useEffect(() => {
  //   axios.defaults.baseURL = "https://api.omas.mobsol.in";
  //   axios.defaults.baseURL = "http://localhost:5000";
  // },[])

  return (
    <Router basename={`${APP_ROOT}`}>
      <ParallaxProvider>
        <Appdata />
      </ParallaxProvider>
    </Router>
  );
}

export default App;
