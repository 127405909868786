import React, { useState, useEffect } from "react";
import { Table, Form, Button, Row, Col } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Common/Message";
import Loader from "../components/Common/Loader";
import {
  getUserDetails,
  updateUser,
  updateUserProfile,
} from "../actions/userActions";
import { listMyOrders } from "../actions/orderActions";
import { USER_UPDATE_PROFILE_RESET } from "../constants/userConstants";
import Layout from "../components/Layout";
import { MdOutlineClose } from "react-icons/md";
import { calculatePrice } from "../utils/calculatePrice";
import { customOrderId } from "../utils/customOrderId";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { GoPlus } from "react-icons/go";

import "react-tabs/style/react-tabs.css";
import "./ProfileTab.scss";
import { ORDER_UPDATE_RESET } from "../constants/orderConstants";
import orderStatus from "../utils/orderStatus";
import { format } from "date-fns";
import { omDateFormat } from "../utils/omDateFormat";

const Profile = ({ location, history }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [address, setAddress] = useState();
  const [addresses, setAddresses] = useState();
  const [message, setMessage] = useState(null);

  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
  const { success } = userUpdateProfile;

  const orderListMy = useSelector((state) => state.orderListMy);
  const { loading: loadingOrders, error: errorOrders, orders } = orderListMy;
  const [showAddAddressForm, setShowAddAddressForm] = useState(false);
  const [shippingAddressPhone, setShippingAddressPhone] = useState("");
  const [shippingAddressName, setShippingAddressName] = useState("");
  const [shippingAddress, setShippingAddress] = useState("");
  const [shippingCity, setShippingCity] = useState("");
  const [shippingPostalCode, setShippingPostalCode] = useState("");
  const [shippingCountry, setShippingCountry] = useState("");
  const orderUpdateDetails = useSelector((state) => state.orderUpdate);

  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = orderUpdateDetails;
  useEffect(() => {
    if (!userInfo) {
      history.push("/login");
    } else {
      if (successUpdate) {
        dispatch({ type: ORDER_UPDATE_RESET });
        dispatch(listMyOrders());
      }
      if (orders && orders.length == 0) {
        dispatch(listMyOrders());
      }
      if (!user || !user.name || success) {
        dispatch({ type: USER_UPDATE_PROFILE_RESET });
        dispatch(getUserDetails("profile"));
        dispatch(listMyOrders());
      } else {
        setName(user.name);
        setEmail(user.email);
        setAddresses(user.addresses);
      }
    }
  }, [dispatch, history, userInfo, user, success, successUpdate]);

  const makeDefaultShipping = (id) => {
    const newList = addresses.map((item) => {
      if (item._id === id) {
        const updatedItem = {
          ...item,
          isDefaultShipping: true,
        };

        return updatedItem;
      } else {
        const updatedItem = {
          ...item,
          isDefaultShipping: false,
        };

        return updatedItem;
      }
    });

    dispatch(
      updateUser({
        _id: user._id,
        addresses: newList,
      })
    );
    //dispatch({ type: USER_UPDATE_PROFILE_RESET });
  };
  const makeDefaultBilling = (id) => {
    const newList = addresses.map((item) => {
      if (item._id === id) {
        const updatedItem = {
          ...item,
          isDefaultBilling: true,
        };

        return updatedItem;
      } else {
        const updatedItem = {
          ...item,
          isDefaultBilling: false,
        };

        return updatedItem;
      }
    });
    dispatch(
      updateUser({
        _id: user._id,
        addresses: newList,
      })
    );
    //dispatch({ type: USER_UPDATE_PROFILE_RESET });
  };
  const submitHandler = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage("Passwords do not match");
    } else {
      dispatch(
        updateUserProfile({ id: user._id, name, email, password, addresses })
      );
    }
  };
  const addAddressHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateUserProfile({
        id: user._id,
        fullAddress: {
          name: shippingAddressName,
          phone: shippingAddressPhone,
          address: shippingAddress,
          city: shippingCity,
          zip: shippingPostalCode,
          country: shippingCountry,
        },
      })
    );
    setShowAddAddressForm(false);
    setShippingAddress("");
    setShippingCity("");
    setShippingPostalCode("");
    setShippingCountry("");
    setShippingAddressName("");
    setShippingAddressPhone("");
  };
  function findPos(obj) {
    var curtop = 0;
    if (obj.offsetParent) {
      do {
        curtop += obj.offsetTop - 100;
      } while ((obj = obj.offsetParent));
      return [curtop];
    }
  }
  const goToForm = (e) => {
    setShowAddAddressForm(true);
    setTimeout(() => {
      window.scroll(0, findPos(document.getElementById("goToForm")));
    }, 1000);
  };

  const addressesBlock = (addresses = null) => (
    <>
      <div className="mb-2 row">
        <div className="ship-boxes">
          <div className="ship-col">
            <div onClick={(e) => goToForm(e)} className="box-1 common-ship-box">
              <GoPlus />
              <h3>Adresse hinzufügen</h3>
            </div>
          </div>
          {addresses &&
            addresses.map((addr, index) => {
              return (
                <div className="ship-col" key={index}>
                  <div className="box-2 common-ship-box">
                    {/* <div className="input-style-box">
                    <input type="radio" name="shipment" id="shipment" />
                    <label for="shipment"></label>
                  </div> */}
                    <div className="address-cover">
                      <h4>{addr.name}</h4>
                      <ul>
                        {addr.address && <li>{addr.address}</li>}
                        {addr.city && <li>{addr.city}</li>}
                        {addr.zip && <li>{addr.zip}</li>}
                        {addr.country && <li>{addr.country}</li>}
                        {addr.phone && <li>{addr.phone}</li>}
                        {addr.isDefaultShipping === true ? (
                          <li className="my-2">Standardversand</li>
                        ) : (
                          <li className="my-2">
                            <Button
                              type="button"
                              onClick={(e) => {
                                e.preventDefault();
                                makeDefaultShipping(addr._id);
                              }}
                              variant="outline-primary"
                              size="sm"
                            >
                              Lieferadresse als Standard
                            </Button>
                          </li>
                        )}
                        {addr.isDefaultBilling === true ? (
                          <li>Standardabrechnung</li>
                        ) : (
                          <li>
                            <Button
                              type="button"
                              onClick={(e) => {
                                e.preventDefault();
                                makeDefaultBilling(addr._id);
                              }}
                              variant="outline-primary"
                              size="sm"
                            >
                              Rechnungsdresse als Standard
                            </Button>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      {showAddAddressForm && (
        <Form onSubmit={addAddressHandler} id="goToForm">
          <Form.Group controlId="address_name">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Name eingeben"
              value={shippingAddressName}
              required
              onChange={(e) => setShippingAddressName(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="address_phone">
            <Form.Label>Telefon</Form.Label>
            <Form.Control
              type="text"
              placeholder="Telefon eingeben"
              value={shippingAddressPhone}
              required
              onChange={(e) => setShippingAddressPhone(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="address">
            <Form.Label>Adresse</Form.Label>
            <Form.Control
              type="text"
              placeholder="Adresse eingeben"
              value={shippingAddress}
              required
              onChange={(e) => setShippingAddress(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="city">
            <Form.Label>Ort</Form.Label>
            <Form.Control
              type="text"
              placeholder="Stadt betreten"
              value={shippingCity}
              required
              onChange={(e) => setShippingCity(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="postalCode">
            <Form.Label>Postleitzahl</Form.Label>
            <Form.Control
              type="text"
              placeholder="Postleitzahl eingeben"
              value={shippingPostalCode}
              required
              onChange={(e) => setShippingPostalCode(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="country">
            <Form.Label>Land</Form.Label>
            <Form.Control
              type="text"
              placeholder="Land eingeben"
              value={shippingCountry}
              required
              onChange={(e) => setShippingCountry(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Button type="submit" variant="primary">
            Speichern
          </Button>
          <Button
            type="reset"
            onClick={(e) => setShowAddAddressForm(false)}
            variant="secondary"
          >
            Abbrechen
          </Button>
        </Form>
      )}
    </>
  );

  return (
    <Layout
      pageClass="profile-page"
      title="Profile"
      description="Omas ecommerce online"
      keywords="omas"
      noHeaderBg="true"
    >
      <section className="without-hero-sections">
        <div className="container">
          <div className="profile-tabs">
            <Tabs>
              <TabList>
                <Tab>Bestellungen</Tab>
                <Tab>Profil bearbeiten</Tab>
                <Tab>Adressen</Tab>
              </TabList>

              <TabPanel>
                <h2>Meine Bestellungen</h2>
                {loadingOrders ? (
                  <Loader />
                ) : errorOrders ? (
                  <Message variant="danger">{errorOrders}</Message>
                ) : (
                  <Table striped bordered hover responsive className="table-sm">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Datum</th>
                        <th>Gesamt</th>
                        <th>STATUS</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {orders.map((order) => (
                        <tr key={order._id}>
                          <td>{customOrderId(order.createdAt)}</td>
                          <td>{omDateFormat(order.createdAt)}</td>
                          <td>{calculatePrice(order.totalPrice)}</td>
                          <td>{orderStatus(order)}</td>
                          <td>
                            <LinkContainer to={`/order/${order._id}`}>
                              <Button className="btn-sm" variant="light">
                                Details
                              </Button>
                            </LinkContainer>
                            {!order.isInvoiced && (
                              <LinkContainer to={`/order/${order._id}/edit`}>
                                <Button className="btn-sm mx-2" variant="light">
                                  Bearbeiten
                                </Button>
                              </LinkContainer>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </TabPanel>
              <TabPanel>
                <h2>Benutzer Profil</h2>
                {message && <Message variant="danger">{message}</Message>}
                {}
                {success && (
                  <Message variant="success">Profile Updated</Message>
                )}
                {loading ? (
                  <Loader />
                ) : error ? (
                  <Message variant="danger">{error}</Message>
                ) : (
                  <Form onSubmit={submitHandler}>
                    <Form.Group controlId="name">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="name"
                        placeholder="Name eingeben"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      ></Form.Control>
                    </Form.Group>

                    <Form.Group controlId="email">
                      <Form.Label>E-Mail</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Email eingeben"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      ></Form.Control>
                    </Form.Group>

                    <Form.Group controlId="password">
                      <Form.Label>Passwort</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Passwort eingeben"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      ></Form.Control>
                    </Form.Group>

                    <Form.Group controlId="confirmPassword">
                      <Form.Label>Passwort bestätigen</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Passwort bestätigen"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      ></Form.Control>
                    </Form.Group>

                    <Button type="submit" variant="primary">
                      Speichern
                    </Button>
                  </Form>
                )}
              </TabPanel>
              <TabPanel>
                <h2>Meine Adressen</h2>
                {addressesBlock(addresses)}
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Profile;
