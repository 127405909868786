import React from "react";
import { Link } from "react-router-dom";
import "./Products.scss";

const Products = ({ products : {name, _id, image, link}}) => {

  return (
    <>
      <Link to={`/category/${_id}`} className="product-block">
          <div className="text-block">
            <h3 className="title-1">{name}</h3>
            {/* {description && <h3 className="title-2">{description}</h3>} */}
            <span className="shop-now">Produktliste</span>
          </div>
          <div className="image-block">
            {/* <img src={`https://api.omas.mobsol.in${image}`} alt="product" className="img-responsive featured-image-thumb" /> */}
          </div>
      </Link>
    </>
  );
};

export default Products;
