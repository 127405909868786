import React from "react";
import { Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const CheckoutSteps = ({ step1, step2, step3, step4, current }) => {
  return (
    <Nav className="justify-content-center mb-4">
      <Nav.Item>
        {!step1 ? (
          <LinkContainer to="/cart">
            <Nav.Link>
              {current == "cart" ? (
                <span className="font-weight-bold">Bestellung</span>
              ) : (
                "Bestellung"
              )}
            </Nav.Link>
          </LinkContainer>
        ) : (
          <Nav.Link disabled>Bestellung</Nav.Link>
        )}
      </Nav.Item>

      <Nav.Item>
        {step2 ? (
          <LinkContainer to="/shipping">
            <Nav.Link>
              {current == "shipping" ? (
                <span className="font-weight-bold">Versand</span>
              ) : (
                "Versand"
              )}
            </Nav.Link>
          </LinkContainer>
        ) : (
          <Nav.Link disabled>Versand</Nav.Link>
        )}
      </Nav.Item>

      <Nav.Item>
        {step3 ? (
          <LinkContainer to="/payment">
            <Nav.Link>
              {current == "payment" ? (
                <span className="font-weight-bold">Zahlungsdetails</span>
              ) : (
                "Zahlungsdetails"
              )}
            </Nav.Link>
          </LinkContainer>
        ) : (
          <Nav.Link disabled>Zahlungsdetails</Nav.Link>
        )}
      </Nav.Item>

      <Nav.Item>
        {step4 ? (
          <LinkContainer to="/placeorder">
            <Nav.Link>
              {current == "placeorder" ? (
                <span className="font-weight-bold">
                  Bestellung abschliessen
                </span>
              ) : (
                "Bestellung abschliessen"
              )}
            </Nav.Link>
          </LinkContainer>
        ) : (
          <Nav.Link disabled>Bestellung abschliessen</Nav.Link>
        )}
      </Nav.Item>
    </Nav>
  );
};

export default CheckoutSteps;
