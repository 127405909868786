import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  Pagination,
  Controller,
  Thumbs,
  Autoplay,
} from "swiper";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import "swiper/swiper-bundle.css";
import "./product-details.scss";
import { IMAGE_URL } from "../../config/config";

SwiperCore.use([Navigation, Pagination, Controller, Thumbs, Autoplay]);

const Slider = ({ title, featuredImage, images }) => {
  const [realitySwiper, setrealitySwiper] = useState(null);
  const [controlledRealSwiper, setcontrolledRealSwiper] = useState(null);

  const onNextClick = () => {
    controlledRealSwiper.slideNext(500);
  };

  const onPreviousClick = () => {
    controlledRealSwiper.slidePrev(500);
  };
  const objImages = [...images];
  objImages.sort((a, b) => a.pOrder - b.pOrder);
  return (
    <>
      <div className="product-slider">
        <Swiper
          id="bigSection"
          className="big-images"
          thumbs={{ swiper: realitySwiper }}
          controller={{ control: controlledRealSwiper }}
          onSwiper={setcontrolledRealSwiper}
          autoplay={false}
          tag="section"
          spaceBetween={0}
          slidesPerView="auto"
          loop={true}
          loopFillGroupWithBlank={true}
        >
          <button
            type="button"
            onClick={onPreviousClick}
            className="left-button"
          >
            <MdKeyboardArrowLeft />
          </button>
          {featuredImage && (
            <SwiperSlide className="big-image" key={"featured-image-1"}>
              <img src={`${IMAGE_URL + featuredImage}`} alt={title} />
              {title && <p className="text-center mt-1">{title}</p>}
            </SwiperSlide>
          )}
          {objImages.length > 0 &&
            objImages.map((item, index) => (
              <SwiperSlide className="big-image" key={index}>
                <img src={`${IMAGE_URL + item.image}`} alt={item.name} />
                {item && <p className="text-center mt-1">{item.name}</p>}
              </SwiperSlide>
            ))}
          <button type="button" onClick={onNextClick} className="right-button">
            <MdKeyboardArrowRight />
          </button>
        </Swiper>

        <Swiper
          id="thumbSection"
          className="thum-images"
          spaceBetween={1}
          slidesPerView="auto"
          onSwiper={setrealitySwiper}
          loop={false}
          loopFillGroupWithBlank={true}
          allowTouchMove={false}
        >
          <button
            type="button"
            onClick={onPreviousClick}
            className="left-button"
          >
            <MdKeyboardArrowLeft />
          </button>
          {featuredImage && (
            <SwiperSlide
              className="font-roboto thum-image"
              key={"featured--thumb-image-1"}
            >
              <img src={`${IMAGE_URL + featuredImage}`} alt={title} />
            </SwiperSlide>
          )}
          {objImages.map((item, index) => (
            <SwiperSlide className="font-roboto thum-image" key={index}>
              <img src={`${IMAGE_URL + item.image}`} alt={item.name} />
            </SwiperSlide>
          ))}
          <button type="button" onClick={onNextClick} className="right-button">
            <MdKeyboardArrowRight />
          </button>
        </Swiper>
      </div>
    </>
  );
};

export default Slider;
