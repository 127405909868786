import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import Layout from "../components/Layout";
// import { IoCart } from 'react-icons/io5';
// import { BsArrowRight, BsArrowLeft } from 'react-icons/bs';
import { axiosInstance } from "../utils/axiosInstance";
// import { useParams } from "react-router-dom";
// import { calculatePrice } from "../utils/calculatePrice";
import "./Products.scss";

const Sellers = () => {
  const [sellers, setSellers] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [sellersPageCount, setSellersPageCount] = useState();

  const fetchSellers = async () => {
    setLoading(true);
    const res = await axiosInstance.get(`/sellers/all`);
    setSellers(res.data.sellers);
    setLoading(false);
  };

  useEffect(() => {
    fetchSellers();
  }, []);

  return (
    <Layout
      pageClass="products"
      title="Sellers"
      description="Omas ecommerce online"
      keywords="omas"
      noHeaderBg="true"
    >
      <section className="without-hero-sections">
        <div className="container">
          <div className="bread-crumbs">
            <ul>
              <li>
                <Link to="/">home</Link>
              </li>
              <li>
                <Link to="#">Verkäufer</Link>
              </li>
            </ul>
          </div>
          <section className="product-all">
            <SellerItem sellers={sellers} loading={loading} />
          </section>
        </div>
      </section>
    </Layout>
  );
};

export default Sellers;

// {items:{price,image,name,description}}
const SellerItem = ({ sellers, loading }) => {
  // const history = useHistory();

  if (loading) {
    return <h2>Loading...</h2>;
  }

  return (
    <>
      {sellers.map((seller) => (
        <div className="product-item" key={seller._id}>
          <h4 className="product-item-title">{seller.sAlias}</h4>
          <Link to={`/sellers/${seller._id}`} className="pro-button-style">
            Ansehen
          </Link>
        </div>
      ))}
    </>
  );
};
