import {
  SELLER_IMAGE_LIST_REQUEST,
  SELLER_IMAGE_LIST_SUCCESS,
  SELLER_IMAGE_LIST_FAIL,
  SELLER_IMAGE_DETAILS_REQUEST,
  SELLER_IMAGE_DETAILS_SUCCESS,
  SELLER_IMAGE_DETAILS_FAIL,
  SELLER_IMAGE_DELETE_REQUEST,
  SELLER_IMAGE_DELETE_SUCCESS,
  SELLER_IMAGE_DELETE_FAIL,
  SELLER_IMAGE_CREATE_RESET,
  SELLER_IMAGE_CREATE_FAIL,
  SELLER_IMAGE_CREATE_SUCCESS,
  SELLER_IMAGE_CREATE_REQUEST,
  SELLER_IMAGE_UPDATE_REQUEST,
  SELLER_IMAGE_UPDATE_SUCCESS,
  SELLER_IMAGE_UPDATE_FAIL,
  SELLER_IMAGE_UPDATE_RESET,
} from "../constants/sellerImageConstants";

export const sellerImageListReducer = (
  state = { sellerImages: [] },
  action
) => {
  switch (action.type) {
    case SELLER_IMAGE_LIST_REQUEST:
      return { loading: true, sellerImages: [] };
    case SELLER_IMAGE_LIST_SUCCESS:
      return {
        loading: false,
        sellerImages: action.payload.sellerImages,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case SELLER_IMAGE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const sellerImageDetailsReducer = (
  state = { sellerImage: { reviews: [] } },
  action
) => {
  switch (action.type) {
    case SELLER_IMAGE_DETAILS_REQUEST:
      return { ...state, loading: true };
    case SELLER_IMAGE_DETAILS_SUCCESS:
      return { loading: false, sellerImage: action.payload };
    case SELLER_IMAGE_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const sellerImageDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case SELLER_IMAGE_DELETE_REQUEST:
      return { loading: true };
    case SELLER_IMAGE_DELETE_SUCCESS:
      return { loading: false, success: true };
    case SELLER_IMAGE_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const sellerImageCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case SELLER_IMAGE_CREATE_REQUEST:
      return { loading: true };
    case SELLER_IMAGE_CREATE_SUCCESS:
      return { loading: false, success: true, sellerImage: action.payload };
    case SELLER_IMAGE_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case SELLER_IMAGE_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const sellerImageUpdateReducer = (
  state = { sellerImage: {} },
  action
) => {
  switch (action.type) {
    case SELLER_IMAGE_UPDATE_REQUEST:
      return { loading: true };
    case SELLER_IMAGE_UPDATE_SUCCESS:
      return { loading: false, success: true, sellerImage: action.payload };
    case SELLER_IMAGE_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case SELLER_IMAGE_UPDATE_RESET:
      return { sellerImage: {} };
    default:
      return state;
  }
};
