import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form, Button, Row, Col, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Common/Message";
import Loader from "../components/Common/Loader";
import { login } from "../actions/userActions";
import Layout from "../components/Layout";
import { PASSWORD_RESET_RESET } from "../constants/userConstants";

const Login = ({ location, history }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;
  const passwordReset = useSelector((state) => state.passwordReset);
  const { success: passwordResetSuceess } = passwordReset;

  const redirect = location.search ? location.search.split("=")[1] : "/";

  useEffect(() => {
    if (userInfo) {
      history.push(redirect);
    }
    if (passwordResetSuceess) {
      dispatch({ type: PASSWORD_RESET_RESET });
    }
  }, [history, userInfo, redirect, passwordResetSuceess]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(login(email, password));
  };

  return (
    <Layout
      pageClass="products"
      title="Login"
      description="Omas ecommerce online"
      keywords="omas"
      noHeaderBg="true"
    >
      <section className="without-hero-sections">
        <Container>
          <Row className="justify-content-md-center">
            <Col xs={12} md={9}>
              <h1>Login</h1>
              {passwordResetSuceess && (
                <Message variant="success">
                  Password has been changed successfully
                </Message>
              )}
              {error && <Message variant="danger">{error}</Message>}
              {loading && <Loader />}
              <Form onSubmit={submitHandler}>
                <Form.Group controlId="email">
                  <Form.Label>E-Mail Adresse</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="E-Mail"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Form.Group controlId="password">
                  <Form.Label>Passwort</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Passwort"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Button type="submit" variant="primary">
                  Login
                </Button>
              </Form>

              <Row className="pt-3 pb-1">
                <Col>
                  Neukunde?
                  <Link
                    to={
                      redirect ? `/register?redirect=${redirect}` : "/register"
                    }
                  >
                    &nbsp;Registrieren
                  </Link>
                </Col>
              </Row>
              <Row>
                <Col>
                  Passwort vergessen?
                  <Link to={"/request-password-reset"}>&nbsp;Zurücksetzen</Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default Login;
