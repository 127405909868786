import React from "react";
import { Link } from "react-router-dom";

const SubCategories = ({item: {_id,name}}) => {
  return (
    <>
      <span className="nav-subs">
        <h4 className="style-1-title">
          <Link to={`/category/${_id}`} className="title-anchor">
            {name}
          </Link>
        </h4>
        {/* <ul className="style-1-ul">
          <li className="style-1-list">
            <Link to="#" className="style-1-anchor">
              CANDIED FANCY NECTARINES
            </Link>
          </li>
        </ul> */}
      </span>
    </>
  );
};

export default SubCategories;
