import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Row,
  Col,
  ListGroup,
  Image,
  Card,
  Container,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Common/Message";
import CheckoutSteps from "../components/Common/CheckoutSteps";
import Layout from "../components/Layout";
import { createOrder } from "../actions/orderActions";
import { ORDER_CREATE_RESET } from "../constants/orderConstants";
import { USER_DETAILS_RESET } from "../constants/userConstants";
import { CART_REFRESH } from "../constants/cartConstants";
import { calculatePrice } from "../utils/calculatePrice";
import { IMAGE_URL } from "../config/config";

const PlaceOrder = ({ history }) => {
  const dispatch = useDispatch();
  const [trigger, setTrigger] = useState(true);
  const [carts, setCarts] = useState({});

  const cart = useSelector((state) => state.cart);

  if (!cart.shipping.shippingAddress?.address) {
    history.push("/shipping");
  } else if (!cart.shipping.billingAddress?.address) {
    history.push("/shipping");
  } else if (!cart.paymentMethod) {
    history.push("/payment");
  }
  //   Calculate prices
  const addDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
  };

  const orderCreate = useSelector((state) => state.orderCreate);
  const { order, success, error } = orderCreate;

  useEffect(() => {
    //dispatch({ type: CART_REFRESH });
    if (success) {
      history.push(`/order/${order._id}`);
      dispatch({ type: USER_DETAILS_RESET });
      dispatch({ type: ORDER_CREATE_RESET });
    }
    if (trigger) {
      setTrigger(false);
      calc();
    }
    // window.location.reload(false);
    // eslint-disable-next-line
  }, [history, success]);
  const calc = () => {
    const cartItemsFromStorage = localStorage.getItem("cartItems")
      ? JSON.parse(localStorage.getItem("cartItems"))
      : [];

    const shippingAddressFromStorage = localStorage.getItem("shipping")
      ? JSON.parse(localStorage.getItem("shipping"))
      : {};
    const paymentMethodFromStorage = localStorage.getItem("paymentMethod")
      ? JSON.parse(localStorage.getItem("paymentMethod"))
      : {};
    carts.itemsPrice = addDecimals(
      cartItemsFromStorage.reduce((acc, item) => acc + item.price * item.qty, 0)
    );
    carts.shippingPrice = addDecimals(
      shippingAddressFromStorage.selectedShippingMethodPrice
    );
    carts.packingMaterialPrice = addDecimals(
      shippingAddressFromStorage.selectedPackingMaterialPrice
    );
    carts.totalPrice = (
      Number(carts.itemsPrice) +
      Number(carts.shippingPrice) +
      Number(carts.packingMaterialPrice)
    ).toFixed(2);
    carts.cartItems = cartItemsFromStorage;
    carts.shippingAddress = shippingAddressFromStorage.shippingAddress;
    carts.billingAddress = shippingAddressFromStorage.billingAddress;
    carts.paymentMethod = paymentMethodFromStorage;
    carts.selectedPackingMaterialId =
      shippingAddressFromStorage.selectedPackingMaterialId;
    carts.selectedShippingMethodId =
      shippingAddressFromStorage.selectedShippingMethodId;
    carts.selectedShippingMethod =
      shippingAddressFromStorage.selectedShippingMethod;
    carts.selectedPackingMaterial =
      shippingAddressFromStorage.selectedPackingMaterial;
  };
  const placeOrderHandler = () => {
    dispatch(
      createOrder({
        orderItems: carts.cartItems,
        shippingAddress: carts.shippingAddress,
        billingAddress: carts.billingAddress,
        paymentMethod: carts.paymentMethod,
        packingMaterial: carts.selectedPackingMaterialId,
        shippingMethod: carts.selectedShippingMethodId,
        itemsPrice: carts.itemsPrice,
        shippingPrice: carts.shippingPrice,
        packingMaterialPrice: carts.packingMaterialPrice,
        totalPrice: carts.totalPrice,
      })
    );
  };

  return (
    <Layout
      pageClass="products"
      title="Place Order"
      description="Omas ecommerce online"
      keywords="omas"
      noHeaderBg="true"
    >
      <section className="without-hero-sections">
        <Container>
          <CheckoutSteps step2 step3 step4 current="placeorder" />
          <Row>
            <Col md={8}>
              {carts && (
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <h2>Rechnungsadresse</h2>
                    <p>
                      <strong>Adresse:</strong>&nbsp;
                      {carts.billingAddress?.address},&nbsp;
                      {carts.billingAddress?.city}&nbsp;
                      {carts.billingAddress?.postalCode},&nbsp;
                      {carts.billingAddress?.country}
                    </p>
                  </ListGroup.Item>

                  <ListGroup.Item>
                    <h2>Lieferadresse</h2>
                    <p>
                      <strong>Adresse:</strong>&nbsp;
                      {carts.shippingAddress?.address},&nbsp;
                      {carts.shippingAddress?.city}&nbsp;
                      {carts.shippingAddress?.postalCode},&nbsp;
                      {carts.shippingAddress?.country}
                    </p>
                  </ListGroup.Item>

                  <ListGroup.Item>
                    <h2>Versandpartner</h2>
                    {carts.selectedShippingMethod}
                  </ListGroup.Item>

                  <ListGroup.Item>
                    <h2>Verpackung + Füllmaterial</h2>
                    {carts.selectedPackingMaterial}
                  </ListGroup.Item>

                  <ListGroup.Item>
                    <h2>Zahlungsmethode</h2>
                    {carts.paymentMethod}
                  </ListGroup.Item>

                  <ListGroup.Item>
                    <h2>Produktliste</h2>
                    {carts.cartItems && carts.cartItems.length === 0 ? (
                      <Message>Der Warenkorb ist leer</Message>
                    ) : (
                      <ListGroup variant="flush">
                        {carts.cartItems &&
                          carts.cartItems.map((item, index) => (
                            <ListGroup.Item key={index}>
                              <Row>
                                <Col md={1}>
                                  <Image
                                    src={`${IMAGE_URL + item.image}`}
                                    alt={item.name}
                                    fluid
                                    rounded
                                  />
                                </Col>
                                <Col>
                                  <Link to={`/product/${item.product}`}>
                                    {item.name}
                                  </Link>
                                  <br />
                                  Verkäufer: {item.seller}
                                </Col>
                                <Col md={4}>
                                  {item.qty} x {calculatePrice(item.price)} ={" "}
                                  {calculatePrice(item.qty * item.price)}
                                </Col>
                              </Row>
                            </ListGroup.Item>
                          ))}
                      </ListGroup>
                    )}
                  </ListGroup.Item>
                </ListGroup>
              )}
            </Col>
            <Col md={4}>
              <Card>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <h2>Bestellübersicht</h2>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col>Produkte</Col>
                      <Col>{calculatePrice(carts.itemsPrice)}</Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col>Versand</Col>
                      <Col>{calculatePrice(carts.shippingPrice)}</Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col>Verpackung</Col>
                      <Col>{calculatePrice(carts.packingMaterialPrice)}</Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col>Total</Col>
                      <Col>{calculatePrice(carts.totalPrice)}</Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col>
                        <small style={{ textTransform: "none" }}>
                          *incl Mwst, incl Verpackung und Versand
                        </small>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  {error && (
                    <ListGroup.Item>
                      <Message variant="danger">{error}</Message>
                    </ListGroup.Item>
                  )}
                  <ListGroup.Item>
                    <Button
                      type="button"
                      className="btn-block"
                      disabled={carts.cartItems === 0}
                      onClick={placeOrderHandler}
                    >
                      Bestellung verbindlich absenden
                    </Button>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default PlaceOrder;
