import React, { useState, useEffect } from "react";
import { format, parseISO } from "date-fns";
import { de } from "date-fns/locale";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";

import {
  Row,
  Col,
  ListGroup,
  Image,
  Card,
  Button,
  Container,
} from "react-bootstrap";
import Layout from "../components/Layout";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Common/Message";
import Loader from "../components/Common/Loader";
import { calculatePrice } from "../utils/calculatePrice";

import {
  getOrderDetails,
  payOrder,
  deliverOrder,
} from "../actions/orderActions";
import {
  ORDER_PAY_RESET,
  ORDER_DELIVER_RESET,
} from "../constants/orderConstants";
import { numberToEuro } from "../utils/calculatePrice";
import { customOrderId } from "../utils/customOrderId";
import "./Order.scss";
import { IMAGE_URL } from "../config/config";
import { omDateFormat } from "../utils/omDateFormat";

const Order = ({ match, history }) => {
  const orderId = match.params.id;

  const dispatch = useDispatch();

  const orderDetails = useSelector((state) => state.orderDetails);
  const { order, loading, error } = orderDetails;

  const orderPay = useSelector((state) => state.orderPay);
  const { loading: loadingPay, success: successPay } = orderPay;

  const orderDeliver = useSelector((state) => state.orderDeliver);
  const { loading: loadingDeliver, success: successDeliver } = orderDeliver;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  if (!loading) {
    //   Calculate prices
    const addDecimals = (num) => {
      return (Math.round(num * 100) / 100).toFixed(2);
    };

    order.itemsPrice = addDecimals(
      order.orderItems.reduce((acc, item) => acc + item.price * item.qty, 0)
    );
  }

  useEffect(() => {
    if (!userInfo) {
      history.push("/login");
    }

    if (!order || successPay || successDeliver || order._id !== orderId) {
      dispatch({ type: ORDER_PAY_RESET });
      dispatch({ type: ORDER_DELIVER_RESET });
      dispatch(getOrderDetails(orderId));
    } else if (!order.isPaid) {
    }
  }, [dispatch, orderId, successPay, successDeliver, order]);

  const successPaymentHandler = () => {
    dispatch(payOrder(orderId));
  };

  const deliverHandler = () => {
    dispatch(deliverOrder(order));
  };
  const buttonHandlerContinueShopping = () => {
    history.push("/products");
  };
  const orderWindow = loading ? (
    <Loader />
  ) : error ? (
    <Message variant="danger">{error}</Message>
  ) : (
    <>
      <h1>Bestellung erfolgreich abgeschlossen</h1>

      <h3>Bestellnummer {customOrderId(order.createdAt)}</h3>
      <Row>
        <Col md={8}>
          <ListGroup variant="flush" className="order-d">
            <ListGroup.Item>
              <h2>Rechnung:</h2>
              <p>
                <strong>Name: </strong> {order.user.name}
              </p>
              <p>
                <strong>Email: </strong>
                <a href={`mailto:${order.user.email}`}>{order.user.email}</a>
              </p>
              <p>
                <strong>Adresse:</strong>&nbsp;
                {order.billingAddress
                  ? `${order.billingAddress.address}, ${order.billingAddress.city}, ${order.billingAddress.postalCode}, ${order.billingAddress.country}`
                  : `${order.shippingAddress.address}, ${order.shippingAddress.city}, ${order.shippingAddress.postalCode}, ${order.shippingAddress.country}`}
              </p>

              <h2>Lieferung:</h2>
              <p>
                <strong>Name: </strong> {order.user.name}
              </p>
              <p>
                <strong>Email: </strong>
                <a href={`mailto:${order.user.email}`}>{order.user.email}</a>
              </p>
              <p>
                <strong>Adresse:</strong>&nbsp;
                {order.shippingAddress.address}, {order.shippingAddress.city}{" "}
                {order.shippingAddress.postalCode},{" "}
                {order.shippingAddress.country}
              </p>
              {order.isDelivered ? (
                <Message variant="success">
                  Delivered on:
                  {format(parseISO(order.deliveredAt), "dd.MM.yyyy", {
                    locale: de,
                  })}
                  <br />
                  Time:
                  {format(parseISO(order.deliveredAt), "HH:mm:ss", {
                    locale: de,
                  })}
                </Message>
              ) : (
                <Message variant="danger">Noch nicht versandt</Message>
              )}
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>Zahlungsart</h2>
              <p>
                <strong>{order.paymentMethod}</strong>
              </p>
              {order.isPaid ? (
                <Message variant="success">
                  Delivered on:
                  {format(parseISO(order.paidAt), "dd.MM.yyyy", { locale: de })}
                  <br />
                  Time:
                  {format(parseISO(order.paidAt), "HH:mm:ss", { locale: de })}
                </Message>
              ) : (
                <Message variant="danger">Noch nicht bezahlt</Message>
              )}
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>Versandpartner</h2>
              <p>
                <strong>{order.shippingMethod?.company}</strong>: (
                {calculatePrice(order.shippingPrice)})
              </p>
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>Verpackung + Füllmaterial</h2>
              <p>
                <strong>{order.packingMaterial?.material}</strong>: (
                {calculatePrice(order.packingMaterialPrice)})
              </p>
              <p>{order.packingMaterial?.description}</p>
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>
                Produktliste
                {!order.isInvoiced && (
                  <LinkContainer to={`/order/${order._id}/edit`}>
                    <Button className="btn-sm mx-2" variant="light">
                      Bearbeiten
                    </Button>
                  </LinkContainer>
                )}
              </h2>
              {order.orderItems.length === 0 ? (
                <Message>Order is empty</Message>
              ) : (
                <ListGroup variant="flush">
                  {order.orderItems.map((item, index) => (
                    <ListGroup.Item key={index}>
                      <Row>
                        <Col md={1}>
                          <Image
                            src={`${IMAGE_URL + item.image}`}
                            alt={item.name}
                            fluid
                            rounded
                          />
                        </Col>
                        <Col>
                          <Link to={`/products/${item.product._id}`}>
                            {item.name}
                          </Link>
                        </Col>
                        <Col md={4}>
                          {item.qty} x {calculatePrice(item.price)} ={" "}
                          {calculatePrice(item.qty * item.price)}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Col>

        <Col md={4}>
          <Card>
            <ListGroup variant="flush">
              <ListGroup.Item className="order-summary">
                <h2>Bestellübersicht</h2>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Datum</Col>
                  <Col>{omDateFormat(order.createdAt)}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Produkte</Col>
                  <Col>{calculatePrice(order.itemsPrice)}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Versand</Col>
                  <Col>{calculatePrice(order.shippingPrice)}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Verpackung</Col>
                  <Col>{calculatePrice(order.packingMaterialPrice)}</Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row>
                  <Col>Total</Col>
                  <Col>{calculatePrice(order.totalPrice)}</Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row>
                  <Col>
                    <small style={{ textTransform: "none" }}>
                      *incl Mwst, incl Verpackung und Versand
                    </small>
                  </Col>
                </Row>
              </ListGroup.Item>

              {/* {(!order.paymentResult || order.paymentResult?.status === 'Failed') && (
                                <ListGroup.Item>
                                    {loadingPay && <Loader />}
                                    <Button
                                        type="button"
                                        className="btn-block"
                                        onClick={successPaymentHandler}
                                    >
                                        Confirm Order
                                    </Button>
                                </ListGroup.Item>
                            )} */}
              {order.paymentResult?.status === "Placed" && (
                <ListGroup.Item>
                  <h3>Order Placed</h3>
                  <p>Order Placed Successfully</p>
                </ListGroup.Item>
              )}
              {loadingDeliver && <Loader />}
              {userInfo &&
                userInfo.isAdmin &&
                order.isPaid &&
                !order.isDelivered && (
                  <ListGroup.Item>
                    <Button
                      type="button"
                      className="btn btn-block"
                      onClick={deliverHandler}
                    >
                      Mark As Delivered
                    </Button>
                  </ListGroup.Item>
                )}
            </ListGroup>
          </Card>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <Button
                type="button"
                className="btn-block"
                onClick={buttonHandlerContinueShopping}
              >
                Weiter einkaufen
              </Button>
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
    </>
  );

  return (
    <Layout
      pageClass="products"
      title="Order"
      description="Order"
      keywords="Order"
      noHeaderBg="true"
    >
      <section className="without-hero-sections">
        <Container>{orderWindow}</Container>
      </section>
    </Layout>
  );
};

export default Order;
