import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from "../../pages/Home";
import Products from "../../pages/Products";
import ProductDetail from "../../pages/ProductDetail";
import Cart from "../../pages/Cart";
import Checkout from "../../pages/Checkout";
import NotFoundPage from "../../pages/NotFoundPage";
import Privacy from "../../pages/Privacy";
import Category from "../../pages/Category";
import Login from "../../pages/Login";
import Register from "../../pages/Register";
import Shipping from "../../pages/Shipping";
import Payment from "../../pages/Payment";
import PlaceOrder from "../../pages/PlaceOrder";
import Order from "../../pages/Order";
import Profile from "../../pages/Profile";
import Gallery from "../../pages/Gallery";
import Error from "../../pages/Error";
import BecomeASeller from "../../pages/BecomeASeller";
import AboutUs from "../../pages/AboutUs";
import Sellers from "../../pages/Sellers";
import SingleSeller from "../../pages/SingleSeller";
import ProductsNew from "../../pages/ProductsNew";
import OrderEdit from "../../pages/OrderEdit";
import DataProtection from "../../pages/DataProtection";
import Disclaimer from "../../pages/Disclaimer";
import ImPrint from "../../pages/ImPrint";
import RequestPasswordReset from "../../pages/RequestPasswordReset";
import PasswordReset from "../../pages/PasswordReset";
const Appdata = () => {
  return (
    <>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/profile" component={Profile} />
        <Route exact path="/products" component={Products} />
        <Route exact path="/products-new" component={ProductsNew} />
        <Route exact path="/products/:id" component={ProductDetail} />
        <Route exact path="/category/:id" component={Category} />
        <Route exact path="/sellers" component={Sellers} />
        <Route exact path="/sellers/:id" component={SingleSeller} />
        <Route exact path="/cart" component={Cart} />
        <Route exact path="/cart/:id?" component={Cart} />
        <Route exact path="/shipping" component={Shipping} />
        <Route exact path="/checkout" component={Checkout} />
        <Route exact path="/payment" component={Payment} />
        <Route exact path="/placeorder" component={PlaceOrder} />
        <Route exact path="/order/:id" component={Order} />
        <Route exact path="/order/:id/edit" component={OrderEdit} />
        <Route exact path="/about-us" component={AboutUs} />
        <Route exact path="/privacy-policy" component={Privacy} />
        <Route exact path="/become-a-seller" component={BecomeASeller} />
        <Route exact path="/galleries/:id" component={Gallery} />
        <Route exact path="/data-protection" component={DataProtection} />
        <Route exact path="/disclaimer" component={Disclaimer} />
        <Route exact path="/imprint" component={ImPrint} />
        <Route exact path="/become-a-seller" component={BecomeASeller} />

        <Route
          exact
          path="/request-password-reset"
          component={RequestPasswordReset}
        />
        <Route exact path="/password-reset/:token" component={PasswordReset} />
        <Route exact path="/error" component={Error} />
        <Route exact path="*" name="404 Not Found" component={NotFoundPage} />
      </Switch>
    </>
  );
};

export default Appdata;
