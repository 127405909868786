import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import Layout from "../components/Layout";
import { IoCart } from "react-icons/io5";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";
import { axiosInstance } from "../utils/axiosInstance";
import { useParams } from "react-router-dom";
import { calculatePrice } from "../utils/calculatePrice";
import "./Products.scss";
import { IMAGE_URL } from "../config/config";

const SingleSeller = () => {
  const [products, setProducts] = useState([]);
  const [sellers, setSetsellers] = useState([]);
  const [seller, setSeller] = useState();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPageCount, setProductsPageCount] = useState();

  const { id } = useParams();

  const fetchSeller = async () => {
    setLoading(true);
    const res = await axiosInstance.get(`/sellers/${id}`);
    setSeller(res.data);
    setLoading(false);
  };

  useEffect(() => {
    fetchSeller();
  }, []);

  const fetchPosts = async (currentPage) => {
    setLoading(true);
    const res = await axiosInstance.get(`/products/by-seller/${id}`);
    setProducts(res.data.products);
    setLoading(false);
  };

  //   useEffect(() => {
  //     axiosInstance.get(`/categories/${id}`).then((response) => {
  //       setProductsPageCount(response.data.pages)
  //     })
  //   },[id])

  useEffect(() => {
    fetchPosts(currentPage);
    if (currentPage === productsPageCount) {
      document.querySelector(".next").style.display = "none";
    } else {
      document.querySelector(".next").style.display = "inline-block";
    }

    if (2 <= currentPage) {
      document.querySelector(".prev").style.display = "inline-block";
    } else {
      document.querySelector(".prev").style.display = "none";
    }
  }, [currentPage, productsPageCount, id]);

  const paginate = (e, pageNumber) => {
    e.preventDefault();
    fetchPosts(pageNumber);
    setCurrentPage(pageNumber);
  };

  const navigateButtons = (e, pageNum) => {
    e.preventDefault();
    if (e.target.classList.contains("next")) {
      setCurrentPage(currentPage + 1);
      fetchPosts(currentPage);
    }

    if (e.target.classList.contains("prev")) {
      setCurrentPage(currentPage - 1);
      fetchPosts(currentPage);
    }
  };

  return (
    <Layout
      pageClass="products"
      title="Products"
      description="Omas ecommerce online"
      keywords="omas"
      noHeaderBg="true"
    >
      <section className="without-hero-sections">
        <div className="container">
          <div className="bread-crumbs">
            <ul>
              <li>
                <Link to="/">home</Link>
              </li>
              <li>
                <Link to="/sellers">Verkäufer</Link>
              </li>
              {seller && (
                <li>
                  <Link to="#">{seller.sAlias}</Link>
                </li>
              )}
            </ul>
          </div>

          {/* {(!loading && seller ) && <section className="product-display">
                    <div className="image-block"></div>
                        <div className="text-block">
                            <h3 className="product-display-title">{seller.sAlias}</h3>
                            <p className="product-display-description">Products sold by {seller.sAlias}</p>
                        </div>
                    </section>} */}

          <section className="product-all">
            <ProductItem products={products} loading={loading} />
          </section>

          <PaginationBar
            paginate={paginate}
            navigateButtons={navigateButtons}
            productsPageCount={productsPageCount}
          />
        </div>
      </section>
    </Layout>
  );
};

export default SingleSeller;

// {items:{price,image,name,description}}
const ProductItem = ({ products, loading }) => {
  const history = useHistory();

  const addToCartHandler = (_id, qty) => {
    history.push(`/cart/${_id}?qty=${qty}`);
  };

  if (loading) {
    return <h2>Loading...</h2>;
  }
  return (
    <>
      {products.map((post) => (
        <div className="product-item" key={post._id}>
          <div className="product-item-image">
            <img
              src={`${IMAGE_URL + post.image}`}
              alt="img"
              className="img1 featured-product-thumb"
            />
            <img
              src={`${IMAGE_URL + post.image}`}
              alt="img"
              className="img2 featured-product-thumb"
            />
          </div>
          <h3 className="price">
            {calculatePrice(post.price, post.discount, post.tax)}{" "}
            {post.discount > 0 && (
              <span className="sub-price">
                {calculatePrice(post.price, 0, post.tax)}
              </span>
            )}
          </h3>
          <h4 className="product-item-title">{post.name}</h4>
          <p className="product-item-description">{post.description}</p>
          <button
            type="button"
            className="pro-button-style"
            disabled={post.countInStock === 0}
            onClick={(e) => addToCartHandler(post._id, 1)}
          >
            <IoCart /> In den Warenkorb
          </button>
          <Link to={`/products/${post._id}`} className="pro-button-style">
            Ansehen
          </Link>
        </div>
      ))}
    </>
  );
};

const PaginationBar = ({ productsPageCount, paginate, navigateButtons }) => {
  const pageNumbers = [];

  for (let i = 1; i <= productsPageCount; i++) {
    pageNumbers.push(i);
  }

  return (
    <section className="pagination-bar">
      {/* <h4 className="page-number">1 – 6 products of 8</h4> */}
      <div className="page-anchors">
        <Link
          to="#"
          className="page-num prev"
          onClick={(e) => navigateButtons(e, pageNumbers)}
        >
          <BsArrowLeft /> Prev
        </Link>
        {pageNumbers.map((number) => (
          <Link
            to="#"
            key={number}
            className={"page-num"}
            onClick={(e) => paginate(e, number)}
          >
            {number}
          </Link>
        ))}
        <Link
          to="#"
          className="page-num next"
          onClick={(e) => navigateButtons(e, pageNumbers)}
        >
          Next <BsArrowRight />
        </Link>
      </div>
    </section>
  );
};
