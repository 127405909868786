import React, { useState, useEffect, useCallback } from "react";
import { format, parseISO } from "date-fns";
import { de } from "date-fns/locale";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  ListGroup,
  Image,
  Card,
  Button,
  Container,
  Form,
} from "react-bootstrap";
import Layout from "../components/Layout";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Common/Message";
import Loader from "../components/Common/Loader";
import { calculatePrice } from "../utils/calculatePrice";

import {
  addProductOrder,
  getOrderDetails,
  updateOrder,
} from "../actions/orderActions";
import { listProducts } from "../actions/productActions";
import { numberToEuro } from "../utils/calculatePrice";
import { customOrderId } from "../utils/customOrderId";
import {
  ORDER_ADD_RESET,
  ORDER_DETAILS_RESET,
  ORDER_LIST_MY_RESET,
  ORDER_LIST_RESET,
  ORDER_UPDATE_RESET,
} from "../constants/orderConstants";
import { PRODUCT_LIST_RESET } from "../constants/productConstants";
import { IMAGE_URL } from "../config/config";

const OrderEdit = ({ match, history }) => {
  const orderId = match.params.id;

  const dispatch = useDispatch();

  const orderDetails = useSelector((state) => state.orderDetails);
  const { order, loading, error } = orderDetails;
  const productList = useSelector((state) => state.productList);
  const { products } = productList;
  const orderAddProduct = useSelector((state) => state.orderAddProduct);
  const {
    loading: loadingAddProduct,
    error: errorAddProduct,
    success: successAddProduct,
  } = orderAddProduct;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [orderItems, setOrderItems] = useState([]);
  const orderUpdateDetails = useSelector((state) => state.orderUpdate);

  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = orderUpdateDetails;
  const [search, setSearch] = useState([]);
  useEffect(() => {
    if (!userInfo) {
      history.push("/login");
    }
    if (successAddProduct) {
      dispatch(getOrderDetails(orderId));
      dispatch({ type: ORDER_ADD_RESET });
      dispatch({ type: PRODUCT_LIST_RESET });
    }
    if (successUpdate) {
      history.push("/profile");
    } else {
      if (!order || order._id !== orderId) {
        dispatch(getOrderDetails(orderId));
      } else {
        setOrderItems(order.orderItems);
      }
    }
  }, [dispatch, orderId, order, history, successUpdate, successAddProduct]);
  const addProductHandler = (orderId, productId) => {
    dispatch(addProductOrder(orderId, productId));
  };
  const searchHandler = (event) => {
    const { value } = event.target;
    setSearch(value);
    dispatch(listProducts(value));
  };
  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const optimisedVerion = useCallback(debounce(searchHandler), []);

  const handleChange = (id, product, newQty) => {
    const newList = orderItems.map((item) => {
      if (item._id === id) {
        let newPrice = parseFloat(product?.price);
        if (product?.discount > 0) {
          newPrice = newPrice - newPrice * (product?.discount / 100);
        }
        // if (product?.tax > 0) {
        //   newPrice = newPrice + newPrice * (product?.tax / 100);
        // }
        newPrice = Number(newPrice).toFixed(2);
        const updatedItem = {
          ...item,
          product: { ...item.product },
          qty: parseInt(newQty),
          price: parseFloat(newPrice),
        };

        return updatedItem;
      }

      return item;
    });
    setOrderItems(newList);
  };
  let billingAddressObj = {};
  if (order && !order?.billingAddress) {
    const { address, addressName, city, postalCode, country } =
      order.shippingAddress;
    billingAddressObj = {
      address,
      addressName,
      city,
      postalCode,
      country,
    };
  } else {
    billingAddressObj = order?.billingAddress;
  }
  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateOrder(orderId, {
        ...order,
        orderItems: orderItems,
        // shippingAddress: order.shippingAddress,
        // paymentMethod: order.paymentMethod,
        // packingMaterial: order.packingMaterial,
        // shippingMethod: order.shippingMethod,
        // shippingPrice: order?.shippingPrice,
        // packingMaterialPrice: order?.packingMaterialPrice,
        billingAddress: billingAddressObj,
        totalPrice: parseFloat(orderTotalPrice),
      })
    );
  };
  const removeProduct = (id) => {
    setOrderItems((items) => items.filter((item) => item._id !== id));
  };
  const addDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
  };
  let orderItemsPrice = addDecimals(
    orderItems.reduce((acc, item) => acc + item.price * item.qty, 0)
  );
  let orderTotalPrice = (
    Number(orderItemsPrice) +
    Number(order?.shippingPrice) +
    Number(order?.packingMaterialPrice)
  ).toFixed(2);
  const orderWindow = loading ? (
    <Loader />
  ) : error ? (
    <Message variant="danger">{error}</Message>
  ) : (
    <>
      <h1>Bestellung bearbeiten {customOrderId(order.createdAt)}</h1>
      <Row>
        <Col md={8}>
          <ListGroup variant="flush" className="order-d">
            <ListGroup.Item>
              <Form.Control
                type="text"
                name="search"
                placeholder="Produkte suchen"
                onChange={optimisedVerion}
              />
            </ListGroup.Item>
            {products &&
              products.map((item, index) => {
                return (
                  <ListGroup.Item key={index}>
                    <Row>
                      <Col md={2}>
                        <Image
                          src={`${IMAGE_URL + item.image}`}
                          alt={item.name}
                          fluid
                          rounded
                        />
                      </Col>
                      <Col md={4}>
                        <Link to={`/products/${item.product}`}>
                          {item.name}
                        </Link>
                        <br />
                        Verkauft von: {item.seller?.sAlias}
                      </Col>
                      <Col md={4}>
                        {calculatePrice(item.price, item.discount, item.tax)}
                      </Col>
                      <Col md={2}>
                        <Button
                          type="button"
                          variant="light"
                          onClick={(e) => addProductHandler(orderId, item._id)}
                        >
                          Add
                        </Button>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                );
              })}
          </ListGroup>
        </Col>
      </Row>

      <Row>
        <Col md={8}>
          <ListGroup variant="flush" className="order-d">
            <ListGroup.Item>
              {/* <h2>Add or Remove Order Items</h2> */}

              {orderItems.length === 0 ? (
                <Message>Order is empty</Message>
              ) : (
                <>
                  <ListGroup variant="flush">
                    <Form onSubmit={submitHandler}>
                      {orderItems.map((item, index) => {
                        let max = 1;
                        if (item.product.countInStock < item.product.maxOrder) {
                          max = item.product.maxOrder;
                        } else {
                          max = item.product.countInStock;
                        }
                        return (
                          <ListGroup.Item key={index}>
                            <Row>
                              <Col md={1}>
                                <Image
                                  src={`${IMAGE_URL + item.image}`}
                                  alt={item.name}
                                  fluid
                                  rounded
                                />
                              </Col>
                              <Col>
                                <Link to={`/products/${item.product}`}>
                                  {item.name}
                                </Link>
                              </Col>
                              <Col md={3}>
                                <Form.Control
                                  type="number"
                                  min="1"
                                  max={max}
                                  value={item.qty}
                                  onChange={(e) =>
                                    handleChange(
                                      item._id,
                                      item.product,
                                      e.target.value
                                    )
                                  }
                                />
                              </Col>
                              <Col md={2}>
                                <Button
                                  type="button"
                                  variant="light"
                                  onClick={() => {
                                    const confirmBox = window.confirm(
                                      "Do you really want to delete this item?"
                                    );
                                    if (confirmBox === true) {
                                      removeProduct(item._id);
                                    }
                                  }}
                                >
                                  Löschen
                                </Button>
                              </Col>
                              <Col md={4}>
                                {item.qty} x {calculatePrice(item.price)} =
                                {calculatePrice(item.qty * item.price)}
                              </Col>
                            </Row>
                          </ListGroup.Item>
                        );
                      })}
                      <Button type="submit" variant="primary">
                        Speichern
                      </Button>
                    </Form>
                  </ListGroup>
                  <Row>
                    <Col md={{ span: 4, offset: 8 }}>
                      <b>Produkte Total *</b> : {orderItemsPrice}
                      <br />
                      <b>Total *</b> :{orderTotalPrice}
                    </Col>
                  </Row>
                </>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
    </>
  );
  return (
    <Layout
      pageClass="products"
      title="Order"
      description="Order"
      keywords="Order"
      noHeaderBg="true"
    >
      <section className="without-hero-sections">
        <Container>{orderWindow}</Container>
      </section>
    </Layout>
  );
};

export default OrderEdit;
