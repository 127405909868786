import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form, Button, Row, Col, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Common/Message";
import Loader from "../components/Common/Loader";
import { requestResetPassword } from "../actions/userActions";
import Layout from "../components/Layout";

const RequestPasswordReset = ({ location, history }) => {
  const [email, setEmail] = useState("");

  const dispatch = useDispatch();

  const userRequestPasswordReset = useSelector(
    (state) => state.requestPasswordReset
  );
  const { loading, error, success } = userRequestPasswordReset;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const redirect = location.search ? location.search.split("=")[1] : "/";

  useEffect(() => {
    if (userInfo) {
      history.push(redirect);
    }
  }, [history, userInfo, redirect]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(requestResetPassword(email));
  };

  return (
    <Layout
      pageClass="products"
      title="Request Password Reset"
      description="Omas ecommerce online"
      keywords="omas"
      noHeaderBg="true"
    >
      <section className="without-hero-sections">
        <Container>
          <Row className="justify-content-md-center">
            <Col xs={12} md={9}>
              <h1>Request Password Reset</h1>
              <p>
                Please fill out your email. A link to reset password will be
                sent there.
              </p>
              {error && <Message variant="danger">{error}</Message>}
              {loading && <Loader />}
              {success && (
                <Message variant="success">
                  Check your email for further instructions.
                </Message>
              )}

              <Form onSubmit={submitHandler}>
                <Form.Group controlId="email">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Email eingeben"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Button type="submit" variant="primary">
                  Send
                </Button>
              </Form>

              <Row className="pt-3 pb-1">
                <Col>
                  Back to
                  <Link to={"/login"}>&nbsp;Login ?</Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default RequestPasswordReset;
