export const SHIPPING_METHOD_LIST_REQUEST = "SHIPPING_METHOD_LIST_REQUEST";
export const SHIPPING_METHOD_LIST_SUCCESS = "SHIPPING_METHOD_LIST_SUCCESS";
export const SHIPPING_METHOD_LIST_FAIL = "SHIPPING_METHOD_LIST_FAIL";

export const SHIPPING_METHOD_DETAILS_REQUEST = "SHIPPING_METHOD_DETAILS_REQUEST";
export const SHIPPING_METHOD_DETAILS_SUCCESS = "SHIPPING_METHOD_DETAILS_SUCCESS";
export const SHIPPING_METHOD_DETAILS_FAIL = "SHIPPING_METHOD_DETAILS_FAIL";

export const SHIPPING_METHOD_DELETE_REQUEST = "SHIPPING_METHOD_DELETE_REQUEST";
export const SHIPPING_METHOD_DELETE_SUCCESS = "SHIPPING_METHOD_DELETE_SUCCESS";
export const SHIPPING_METHOD_DELETE_FAIL = "SHIPPING_METHOD_DELETE_FAIL";

export const SHIPPING_METHOD_CREATE_REQUEST = "SHIPPING_METHOD_CREATE_REQUEST";
export const SHIPPING_METHOD_CREATE_SUCCESS = "SHIPPING_METHOD_CREATE_SUCCESS";
export const SHIPPING_METHOD_CREATE_FAIL = "SHIPPING_METHOD_CREATE_FAIL";
export const SHIPPING_METHOD_CREATE_RESET = "SHIPPING_METHOD_CREATE_RESET";

export const SHIPPING_METHOD_UPDATE_REQUEST = "SHIPPING_METHOD_UPDATE_REQUEST";
export const SHIPPING_METHOD_UPDATE_SUCCESS = "SHIPPING_METHOD_UPDATE_SUCCESS";
export const SHIPPING_METHOD_UPDATE_FAIL = "SHIPPING_METHOD_UPDATE_FAIL";
export const SHIPPING_METHOD_UPDATE_RESET = "SHIPPING_METHOD_UPDATE_RESET";

export const SHIPPING_METHOD_CREATE_REVIEW_REQUEST = "SHIPPING_METHOD_CREATE_REVIEW_REQUEST";
export const SHIPPING_METHOD_CREATE_REVIEW_SUCCESS = "SHIPPING_METHOD_CREATE_REVIEW_SUCCESS";
export const SHIPPING_METHOD_CREATE_REVIEW_FAIL = "SHIPPING_METHOD_CREATE_REVIEW_FAIL";
export const SHIPPING_METHOD_CREATE_REVIEW_RESET = "SHIPPING_METHOD_CREATE_REVIEW_RESET";

export const SHIPPING_METHOD_TOP_REQUEST = "SHIPPING_METHOD_TOP_REQUEST";
export const SHIPPING_METHOD_TOP_SUCCESS = "SHIPPING_METHOD_TOP_SUCCESS";
export const SHIPPING_METHOD_TOP_FAIL = "SHIPPING_METHOD_TOP_FAIL";
