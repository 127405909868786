import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../utils/axiosInstance";
import "./Nav.scss";
import SubCategories from "./SubCategories";
import Popup from "../Common/Popup";

const Nav = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [sellers, setSellers] = useState([]);
  const [contactPopup, setContactPopup] = useState(false);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [requestType, setRequestType] = useState("Kontakt");
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState(0);
  useEffect(() => {
    document.addEventListener("click", function (event) {
      const allListItem = document.getElementsByClassName("main-list-item");
      const menuElement = event.target;
      const parentElem = menuElement.parentElement;
      if (parentElem.classList.contains("main-list-item")) {
        for (var i = 0; i < allListItem.length; i++) {
          allListItem[i].classList.remove("active");
        }

        if (parentElem.children[1]) {
          parentElem.classList.add("active");
        }
      }
    });
  }, []);

  const fetchCategory = async () => {
    setLoading(true);
    const res = await axiosInstance.get(`/categories`);
    setCategories(res.data.categories);
    setLoading(false);
  };

  const fetchSellers = async () => {
    setLoading(true);
    const res = await axiosInstance.get(`/sellers/all`);
    setSellers(res.data.sellers);
    setLoading(false);
  };

  useEffect(() => {
    fetchCategory();
    fetchSellers();
    if (userInfo) {
      setFullName(userInfo.name);
      setEmail(userInfo.email);
    }
    if (!contactPopup) {
      setAlert(0);
    }
  }, [contactPopup]);
  useEffect(() => {
    let interval;
    if (alert == 2) {
      interval = setInterval(() => {
        setContactPopup(false);
      }, 2000);
    }
    return () => clearInterval(interval);
  }, [alert]);
  const hamMenu = () => {
    const Nav = document.querySelector("nav");
    if (Nav.classList.contains("active")) Nav.classList.remove("active");
    else Nav.classList.add("active");
  };

  // document.addEventListener('click', function(e){
  //   const getSubHeight = document.querySelector('.big-menu-spacer').scrollHeight;
  //   if ( e.target.classList.contains('sub') ){
  //     if(e.target.nextSibling.classList.contains('active')){
  //       e.target.nextSibling.classList.remove('active');
  //       e.target.nextSibling.style.height = 0 + 'px';
  //     }
  //     else {
  //       e.target.nextSibling.classList.add('active');
  //       e.target.nextSibling.style.height = getSubHeight + 'px';
  //     }
  //   }
  // });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setAlert(1);
    try {
      const data = JSON.stringify({
        fullName: fullName,
        email: email,
        subject: subject,
        message: message,
        requestType: requestType,
      });
      const res = await axiosInstance.post("/contacts", data);
      if (res.status === 201) {
        setFullName("");
        setEmail("");
        setSubject("");
        setMessage("");
        setAlert(2);
      } else {
        setAlert(3);
      }
    } catch (err) {
      setAlert(3);
    }
  };
  let showAlert = "";
  if (alert == 1) {
    showAlert = <p>Submitting ...</p>;
  } else if (alert == 2) {
    showAlert = (
      <div role="alert" class="fade alert alert-success show">
        Danke uns zu kontaktieren
      </div>
    );
  } else if (alert == 3) {
    showAlert = (
      <div role="alert" class="fade alert alert-danger show">
        Some error occurred !
      </div>
    );
  } else {
    showAlert = "";
  }
  return (
    <>
      <div className="navigation">
        <Link to="#" className="hamburger" onClick={() => hamMenu()}>
          <GiHamburgerMenu />
        </Link>
        <nav>
          <ul className="main-list">
            <li className="main-list-item menuItem">
              <Link to="/" className="main-list-item-anchor active">
                Home
              </Link>
            </li>
            <li className="main-list-item">
              <Link to="#" className="main-list-item-anchor sub">
                Produkte
              </Link>
              <span className="big-menu-spacer">
                <span className="big-menu style-1">
                  <>
                    <span className="nav-subs">
                      <h4 className="style-1-title">
                        <Link to={`/products`} className="title-anchor">
                          Alle Produkte
                        </Link>
                      </h4>
                    </span>
                  </>
                  {!loading &&
                    categories &&
                    categories.map((item, index) => (
                      <SubCategories item={item} key={`nav-item-${index}`} />
                    ))}
                </span>
              </span>
            </li>
            <li className="main-list-item">
              <Link to="#" className="main-list-item-anchor sub">
                Verkäufer
              </Link>
              <span className="big-menu-spacer">
                <span className="big-menu style-1">
                  <>
                    {!loading &&
                      sellers &&
                      sellers.map((seller, index) => {
                        return (
                          <span
                            key={`seller-menu-${index}`}
                            className="nav-subs"
                          >
                            <h4 className="style-1-title">
                              <Link
                                to={`/sellers/${seller._id}`}
                                className="title-anchor"
                              >
                                {seller.sAlias}
                              </Link>
                            </h4>
                          </span>
                        );
                      })}
                  </>
                </span>
              </span>
            </li>
            <li className="main-list-item">
              <Link to="/products-new" className="main-list-item-anchor">
                Neuheiten
              </Link>
              {/* <span className="big-menu-spacer">
                <span className="big-menu style-3">
                  <span className="style-2-items">
                    <img src="/assets/images/malaysian-satay-seasoning_01_grande.png" alt="img" className="img-responsive" />
                  </span>
                  <span className="style-2-items">
                    <img src="/assets/images/malaysian-satay-seasoning_01_grande.png" alt="img" className="img-responsive" />
                  </span>
                  <span className="style-2-items">
                    <img src="/assets/images/malaysian-satay-seasoning_01_grande.png" alt="img" className="img-responsive" />
                  </span>
                  <span className="style-2-items">
                    <img src="/assets/images/malaysian-satay-seasoning_01_grande.png" alt="img" className="img-responsive" />
                  </span>
                </span>
              </span> */}
            </li>
            <li className="main-list-item">
              <Link to="/about-us" className="main-list-item-anchor">
                Über uns
              </Link>
            </li>
            <li className="main-list-item">
              <Link
                to="#"
                className="main-list-item-anchor"
                onClick={() => setContactPopup(true)}
              >
                Kontakt
              </Link>
            </li>
            <li className="main-list-item">
              <Link to="/become-a-seller" className="main-list-item-anchor">
                Verkäufer werden
              </Link>
            </li>
          </ul>
        </nav>
        <div className="form-popup">
          <Popup trigger={contactPopup} setTrigger={setContactPopup}>
            <h4 className="slider-title">Kontakt zu Omas-Laden:</h4>
            <p>
              E-mail: <a href="mailto:mail@omas-laden.at">mail@omas-laden.at</a>
            </p>
            <p>
              Telefon: <a href="tel: +43 699 14107 207"> +43 699 14107 207 </a>
            </p>
            {showAlert}
            <h5>
              <span>Oder: On-Line Formular</span>
            </h5>
            <form onSubmit={handleSubmit}>
              <label>User Name</label>
              {userInfo ? (
                <p>{userInfo.name}</p>
              ) : (
                <input
                  type="text"
                  value={fullName}
                  placeholder=""
                  onChange={(e) => setFullName(e.target.value)}
                />
              )}
              <label>Email</label>
              {userInfo ? (
                <p>{userInfo.email}</p>
              ) : (
                <input
                  type="text"
                  value={email}
                  placeholder=""
                  onChange={(e) => setEmail(e.target.value)}
                />
              )}
              <label>Betreff</label>
              <input
                type="text"
                value={subject}
                placeholder=""
                onChange={(e) => setSubject(e.target.value)}
              />
              <label>Nachricht</label>
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <button type="submit" className="submit_btn">
                Absenden
              </button>
              <a
                type="button"
                className="cancel_btn"
                onClick={() => setContactPopup(false)}
              >
                Abbrechen
              </a>
            </form>
          </Popup>
        </div>
      </div>
    </>
  );
};

export default Nav;
