import {
  PACKING_MATERIAL_LIST_REQUEST,
  PACKING_MATERIAL_LIST_SUCCESS,
  PACKING_MATERIAL_LIST_FAIL,
  PACKING_MATERIAL_DETAILS_REQUEST,
  PACKING_MATERIAL_DETAILS_SUCCESS,
  PACKING_MATERIAL_DETAILS_FAIL,
  PACKING_MATERIAL_DELETE_REQUEST,
  PACKING_MATERIAL_DELETE_SUCCESS,
  PACKING_MATERIAL_DELETE_FAIL,
  PACKING_MATERIAL_CREATE_RESET,
  PACKING_MATERIAL_CREATE_FAIL,
  PACKING_MATERIAL_CREATE_SUCCESS,
  PACKING_MATERIAL_CREATE_REQUEST,
  PACKING_MATERIAL_UPDATE_REQUEST,
  PACKING_MATERIAL_UPDATE_SUCCESS,
  PACKING_MATERIAL_UPDATE_FAIL,
  PACKING_MATERIAL_UPDATE_RESET,
  PACKING_MATERIAL_CREATE_REVIEW_REQUEST,
  PACKING_MATERIAL_CREATE_REVIEW_SUCCESS,
  PACKING_MATERIAL_CREATE_REVIEW_FAIL,
  PACKING_MATERIAL_CREATE_REVIEW_RESET,
  PACKING_MATERIAL_TOP_REQUEST,
  PACKING_MATERIAL_TOP_SUCCESS,
  PACKING_MATERIAL_TOP_FAIL,
} from "../constants/packingMaterialConstants";

export const packingMaterialListReducer = (state = { packingMaterials: [] }, action) => {
  switch (action.type) {
    case PACKING_MATERIAL_LIST_REQUEST:
      return { loading: true, packingMaterials: [] };
    case PACKING_MATERIAL_LIST_SUCCESS:
      return {
        loading: false,
        packingMaterials: action.payload.packingMaterials,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case PACKING_MATERIAL_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const packingMaterialDetailsReducer = (
  state = { packingMaterial: { reviews: [] } },
  action
) => {
  switch (action.type) {
    case PACKING_MATERIAL_DETAILS_REQUEST:
      return { ...state, loading: true };
    case PACKING_MATERIAL_DETAILS_SUCCESS:
      return { loading: false, packingMaterial: action.payload };
    case PACKING_MATERIAL_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const packingMaterialDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case PACKING_MATERIAL_DELETE_REQUEST:
      return { loading: true };
    case PACKING_MATERIAL_DELETE_SUCCESS:
      return { loading: false, success: true };
    case PACKING_MATERIAL_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const packingMaterialCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PACKING_MATERIAL_CREATE_REQUEST:
      return { loading: true };
    case PACKING_MATERIAL_CREATE_SUCCESS:
      return { loading: false, success: true, packingMaterial: {} };
    case PACKING_MATERIAL_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case PACKING_MATERIAL_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const packingMaterialUpdateReducer = (state = { packingMaterial: {} }, action) => {
  switch (action.type) {
    case PACKING_MATERIAL_UPDATE_REQUEST:
      return { loading: true };
    case PACKING_MATERIAL_UPDATE_SUCCESS:
      return { loading: false, success: true, packingMaterial: action.payload };
    case PACKING_MATERIAL_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case PACKING_MATERIAL_UPDATE_RESET:
      return { packingMaterial: {} };
    default:
      return state;
  }
};

export const packingMaterialReviewCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PACKING_MATERIAL_CREATE_REVIEW_REQUEST:
      return { loading: true };
    case PACKING_MATERIAL_CREATE_REVIEW_SUCCESS:
      return { loading: false, success: true };
    case PACKING_MATERIAL_CREATE_REVIEW_FAIL:
      return { loading: false, error: action.payload };
    case PACKING_MATERIAL_CREATE_REVIEW_RESET:
      return {};
    default:
      return state;
  }
};

export const packingMaterialTopRatedReducer = (state = { packingMaterials: [] }, action) => {
  switch (action.type) {
    case PACKING_MATERIAL_TOP_REQUEST:
      return { loading: true, packingMaterials: [] };
    case PACKING_MATERIAL_TOP_SUCCESS:
      return { loading: false, packingMaterials: action.payload };
    case PACKING_MATERIAL_TOP_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
