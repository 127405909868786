import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../utils/axiosInstance";
import { useHistory } from "react-router";
import { calculatePrice } from "../../utils/calculatePrice";
import Popup from "../Common/Popup";
import PopupSlider from "../Common/PopupSlider";
import "./product-details.scss";

const ProductItemDetails = ({ productDetails: { product, sellerImages } }) => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const {
    _id,
    name,
    price,
    discount,
    tax,
    description,
    seller,
    countInStock,
    maxOrderQuantity,
    unit,
  } = product;
  const [buttonPopup, setButtonPopup] = useState(false);
  const [qtyPopup, setQtyPopup] = useState(false);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [requestType, setRequestType] = useState("");
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState(0);
  const [qty, setQty] = useState(1);
  const history = useHistory();
  useEffect(() => {
    document.body.classList.toggle("modal-open", buttonPopup);
  }, [buttonPopup]);
  useEffect(() => {
    if (userInfo) {
      setFullName(userInfo.name);
      setEmail(userInfo.email);
    }
    if (!qtyPopup) {
      setAlert(0);
    }
  }, [qtyPopup, userInfo]);
  const addToCartHandler = () => {
    history.push(`/cart/${_id}?qty=${qty}`);
  };
  useEffect(() => {
    let interval;
    if (alert == 2) {
      interval = setInterval(() => {
        setQtyPopup(false);
      }, 2000);
    }
    return () => clearInterval(interval);
  }, [alert]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setAlert(1);
    try {
      const data = JSON.stringify({
        fullName: fullName,
        email: email,
        subject: subject,
        message: message,
        requestType: requestType,
      });
      const res = await axiosInstance.post("/contacts", data);
      if (res.status === 201) {
        setFullName("");
        setEmail("");
        setSubject("");
        setMessage("");
        setAlert(2);
      } else {
        setAlert(3);
      }
    } catch (err) {
      setAlert(3);
    }
  };
  let showAlert = "";
  if (alert == 1) {
    showAlert = <p>Submitting ...</p>;
  } else if (alert == 2) {
    showAlert = (
      <div role="alert" class="fade alert alert-success show">
        Danke uns zu kontaktieren
      </div>
    );
  } else if (alert == 3) {
    showAlert = (
      <div role="alert" class="fade alert alert-danger show">
        Some error occurred !
      </div>
    );
  } else {
    showAlert = "";
  }
  const orderLimit =
    countInStock < maxOrderQuantity ? countInStock : maxOrderQuantity;
  return (
    <>
      <div className="product-details">
        <h3 className="product-details-title">{name}</h3>
        <h4 className="product-price">
          {calculatePrice(price, discount, tax)}{" "}
          {discount > 0 && <span>{calculatePrice(price, 0, tax)}</span>}
        </h4>
        <h4 className="product-amount">
          Lager:
          {countInStock > 0 ? (
            <span>Lagernd</span>
          ) : (
            <>
              <span style={{ color: "red" }}>Nicht lagernd</span>
              <small className="d-block mt-1">
                Für Reservierungen kontaktieren Sie uns bitte.
                <button
                  type="button"
                  className="btn-qty-popup"
                  onClick={() => {
                    setRequestType("Kontakt");
                    setQtyPopup(true);
                  }}
                >
                  Kontakt omas-laden
                </button>
              </small>
            </>
          )}
          {countInStock > 0 && countInStock <= 5 ? (
            <small className="d-block mt-3 text-danger">
              5 Stück auf Lager
            </small>
          ) : (
            ""
          )}
        </h4>
        <div className="product-quantity-button">
          <div className="product-qty">
            <label>Anzahl:</label>
            <input
              type="number"
              value={qty}
              min="1"
              max={orderLimit}
              onChange={(e) => setQty(e.target.value)}
              className="product-value"
            />
            <label>{unit == "Litre" ? "Liter" : unit}</label>
          </div>
          <div className="product-button-group">
            <button
              type="button"
              disabled={countInStock === 0}
              onClick={addToCartHandler}
            >
              In den Warenkorb
            </button>
            {sellerImages.length > 0 && (
              <button type="button" onClick={() => setButtonPopup(true)}>
                Vendor Gallery
              </button>
            )}
          </div>
        </div>
        <div>
          {qty >= orderLimit && (
            <small className="d-block mt-1">
              Die maximale Bestellmenge ist erreicht.
              <br />
              Wenn Sie mehr brauchen kontaktieren Sie uns bitte.
              <button
                type="button"
                className="btn-qty-popup"
                onClick={() => {
                  setRequestType(`Bulk Order - ${name}`);
                  setQtyPopup(true);
                }}
              >
                Kontakt omas-laden
              </button>
            </small>
          )}
        </div>
        {product?.category?.name && (
          <h5 className="product-info">
            Typ: <span>{product?.category?.name}</span>
          </h5>
        )}
        {seller && (
          <h5 className="product-info">
            Vendor:
            <span>
              <Link to={`/sellers/${seller._id}`}>{seller.sAlias}</Link>
            </span>
          </h5>
        )}
        <h6 className="product-description-title">Beschreibung</h6>
        <p className="product-description">{description}</p>
        <button
          type="button"
          className="contact-button"
          onClick={() => {
            setRequestType("Kontakt");
            setQtyPopup(true);
          }}
        >
          Kontakt
        </button>

        <Popup trigger={buttonPopup} setTrigger={setButtonPopup}>
          <h4 className="slider-title">{seller.sAlias}</h4>
          {sellerImages.length > 0 && <PopupSlider images={sellerImages} />}
          {/* <p className="slider-description">
            Only very rich people could afford buying spices. Nowadays spices
            have become a casual part of all the types of cuisine. We can`t
            imagine our life without pepper or cinnamon. We are proud to present
            our unique products. You`ll be amazed because we have a largest
            choice of tremendous spices. We provide only premium quality goods
            at a fair price.
          </p> */}
        </Popup>

        <div className="form-popup">
          <Popup trigger={qtyPopup} setTrigger={setQtyPopup}>
            <h4 className="slider-title">Kontakt zu Omas-Laden:</h4>
            <p>
              E-mail: <a href="mailto:mail@omas-laden.at">mail@omas-laden.at</a>
            </p>
            <p>
              Telefon: <a href="tel: +43 699 14107 207"> +43 699 14107 207 </a>
            </p>
            {showAlert}
            <h5>
              <span>Oder: On-Line Formular</span>
            </h5>
            <form onSubmit={handleSubmit}>
              <label>User Name</label>
              {userInfo ? (
                <p>{userInfo.name}</p>
              ) : (
                <input
                  type="text"
                  value={fullName}
                  placeholder=""
                  onChange={(e) => setFullName(e.target.value)}
                />
              )}
              <label>Email</label>
              {userInfo ? (
                <p>{userInfo.email}</p>
              ) : (
                <input
                  type="text"
                  value={email}
                  placeholder=""
                  onChange={(e) => setEmail(e.target.value)}
                />
              )}
              <label>Betreff</label>
              <input
                type="text"
                value={subject}
                placeholder=""
                onChange={(e) => setSubject(e.target.value)}
              />
              <label>Nachricht</label>
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <button type="submit" className="submit_btn">
                Absenden
              </button>
              <a
                type="button"
                className="cancel_btn"
                onClick={() => setQtyPopup(false)}
              >
                Abbrechen
              </a>
            </form>
          </Popup>
        </div>
      </div>
    </>
  );
};

export default ProductItemDetails;
