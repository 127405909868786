import React from "react";
import "./Checkbox.scss";

const Checkbox = (props) => {
  return (
    <>
      <div className="inline-checkbox">
        <input type="checkbox" id={props.id} />
        <label for={props.id}></label>
        <label for={props.id}>{props.label}</label>
      </div>
    </>
  );
};

export default Checkbox;
