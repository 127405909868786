import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { axiosInstance } from "../utils/axiosInstance";
import Layout from "../components/Layout";
import Banner from "../components/Home/Banner";
import Products from "../components/Home/Products";
// import ProductData from "../data/ProductData";
// import ProductSlide from "../components/Home/ProductSlide";
import Best from "../components/Home/Best";
import Blog from "../components/Home/Blog";
// import BlogData from '../data/BlogData';
import { listProductsFeatured } from "../actions/productActions";



const Home = () => {
  const dispatch = useDispatch();
  const productFeaturedList = useSelector((state) => state.productFeaturedList);
  // const { productsFeatured } = productFeaturedList;
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [galleries, setGalleries] = useState([]);

  useEffect(() => {
    dispatch(listProductsFeatured());
  }, [dispatch]);

  const fetchCategory = async () => {
    setLoading(true);
    const res = await axiosInstance.get(`/categories`);
    setCategories(res.data.categories);
    const resGalleries = await axiosInstance.get(`/galleries`);
    setGalleries(resGalleries.data.galleries);
    setLoading(false);
  }

  useEffect(() => {
    fetchCategory();
  }, []);

  return (
    <Layout
      pageClass="home"
      title="Home"
      description="Omas ecommerce online"
      keywords="omas"
      noHeaderBg="true"
    >
      <Banner />
      <section className="product-section">
        <div className="product-cards">
          <div className="container">
            <h3 className="main-title">Produktgruppen</h3>
          </div>
          <div className="container">
            {(!loading && categories?.length > 0) && categories.map((category, index) => (
              <Products key={"categories-" + index} products={category} />
            ))}
          </div>
        </div>
        {/* {productsFeatured && <ProductSlide products={productsFeatured} />} */}
      </section>
      <Best />
      <section className="blog-section">
        <div className="container">
          {(!loading && galleries?.length > 0) && galleries.map((gallery) => (
            <Blog key={'blog-' + gallery._id} blogItems={gallery} />
          ))}
        </div>
      </section>
    </Layout>
  );
};

export default Home;


