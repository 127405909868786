export const PACKING_MATERIAL_LIST_REQUEST = "PACKING_MATERIAL_LIST_REQUEST";
export const PACKING_MATERIAL_LIST_SUCCESS = "PACKING_MATERIAL_LIST_SUCCESS";
export const PACKING_MATERIAL_LIST_FAIL = "PACKING_MATERIAL_LIST_FAIL";

export const PACKING_MATERIAL_DETAILS_REQUEST = "PACKING_MATERIAL_DETAILS_REQUEST";
export const PACKING_MATERIAL_DETAILS_SUCCESS = "PACKING_MATERIAL_DETAILS_SUCCESS";
export const PACKING_MATERIAL_DETAILS_FAIL = "PACKING_MATERIAL_DETAILS_FAIL";

export const PACKING_MATERIAL_DELETE_REQUEST = "PACKING_MATERIAL_DELETE_REQUEST";
export const PACKING_MATERIAL_DELETE_SUCCESS = "PACKING_MATERIAL_DELETE_SUCCESS";
export const PACKING_MATERIAL_DELETE_FAIL = "PACKING_MATERIAL_DELETE_FAIL";

export const PACKING_MATERIAL_CREATE_REQUEST = "PACKING_MATERIAL_CREATE_REQUEST";
export const PACKING_MATERIAL_CREATE_SUCCESS = "PACKING_MATERIAL_CREATE_SUCCESS";
export const PACKING_MATERIAL_CREATE_FAIL = "PACKING_MATERIAL_CREATE_FAIL";
export const PACKING_MATERIAL_CREATE_RESET = "PACKING_MATERIAL_CREATE_RESET";

export const PACKING_MATERIAL_UPDATE_REQUEST = "PACKING_MATERIAL_UPDATE_REQUEST";
export const PACKING_MATERIAL_UPDATE_SUCCESS = "PACKING_MATERIAL_UPDATE_SUCCESS";
export const PACKING_MATERIAL_UPDATE_FAIL = "PACKING_MATERIAL_UPDATE_FAIL";
export const PACKING_MATERIAL_UPDATE_RESET = "PACKING_MATERIAL_UPDATE_RESET";

export const PACKING_MATERIAL_CREATE_REVIEW_REQUEST = "PACKING_MATERIAL_CREATE_REVIEW_REQUEST";
export const PACKING_MATERIAL_CREATE_REVIEW_SUCCESS = "PACKING_MATERIAL_CREATE_REVIEW_SUCCESS";
export const PACKING_MATERIAL_CREATE_REVIEW_FAIL = "PACKING_MATERIAL_CREATE_REVIEW_FAIL";
export const PACKING_MATERIAL_CREATE_REVIEW_RESET = "PACKING_MATERIAL_CREATE_REVIEW_RESET";

export const PACKING_MATERIAL_TOP_REQUEST = "PACKING_MATERIAL_TOP_REQUEST";
export const PACKING_MATERIAL_TOP_SUCCESS = "PACKING_MATERIAL_TOP_SUCCESS";
export const PACKING_MATERIAL_TOP_FAIL = "PACKING_MATERIAL_TOP_FAIL";
