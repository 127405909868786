import React, { useState, useEffect } from "react";
import { Form, Button, Col, Container, Alert } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../components/Layout";
import CheckoutSteps from "../components/Common/CheckoutSteps";
import { savePaymentMethod } from "../actions/cartActions";
import "./Shipping.scss";

const Payment = ({ history }) => {
  const cart = useSelector((state) => state.cart);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [showError, setShowError] = useState();
  const { shipping } = cart;
  if (!shipping?.shippingAddress?.address) {
    history.push("/shipping");
  }
  useEffect(() => {
    if (!userInfo) {
      history.push("/login");
    } else {
      if (!paymentMethod) {
        setPaymentMethod("Zahlung auf Rechnung");
      }
    }
  }, [history, userInfo]);

  const [paymentMethod, setPaymentMethod] = useState();

  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    if (!paymentMethod) {
      setShowError(true);
    } else {
      setShowError();
      dispatch(savePaymentMethod(paymentMethod));
      history.push("/placeorder");
    }
  };

  return (
    <Layout
      pageClass="products"
      title="Payment Method"
      description="Omas ecommerce online"
      keywords="omas"
      noHeaderBg="true"
    >
      <section className="without-hero-sections title-adjustments">
        <Container>
          <CheckoutSteps step2 step3 current="payment" />
          <h1>Zahlungsmethode</h1>
          {showError && (
            <Alert variant="danger">Bitte Zahlungsmethode auswählen</Alert>
          )}
          <Form onSubmit={submitHandler} className="payment-methods">
            <Form.Group>
              {/* <Form.Label as="legend">Select Method</Form.Label> */}
              <Col>
                {/* <Form.Check
                  className="d-block mb-1"
                  type="radio"
                  label="Cash On Delivery"
                  id="CashOnDelivery"
                  name="paymentMethod"
                  value="Cash On Delivery"
                  onClick={(e) => setPaymentMethod(e.target.value)}
                ></Form.Check> */}

                <Form.Check
                  className="d-block mb-1"
                  type="radio"
                  label="Bezahlung auf Rechnung"
                  id="PayOnInvoce"
                  name="paymentMethod"
                  value="Zahlung auf Rechnung"
                  defaultChecked={true}
                  onClick={(e) => setPaymentMethod(e.target.value)}
                ></Form.Check>
              </Col>
            </Form.Group>
            <Button type="submit" className="mt-2" variant="primary">
              Weiter
            </Button>
          </Form>
        </Container>
      </section>
    </Layout>
  );
};

export default Payment;
