import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../components/Layout";
import { AiFillDelete } from "react-icons/ai";
import Popup from "../components/Common/Popup";
import { axiosInstance } from "../utils/axiosInstance";

import {
  Row,
  Col,
  ListGroup,
  Image,
  Form,
  Button,
  Card,
} from "react-bootstrap";
import Message from "../components/Common/Message";
import { addToCart, removeFromCart } from "../actions/cartActions";
import { faCoffee } from "react-icons/fa";

import "./Cart.scss";
import { calculatePrice, calculateTotalPrice } from "../utils/calculatePrice";
import { IMAGE_URL } from "../config/config";

const Cart = ({ match, location, history }) => {
  const productId = match.params.id;

  const qty = location.search ? Number(location.search.split("=")[1]) : 1;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [contactPopup, setContactPopup] = useState(false);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [requestType, setRequestType] = useState("");
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState(0);
  const dispatch = useDispatch();

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;
  let cartTotalPrice = cartItems.reduce(
    (acc, item) => acc + item.qty * item.price,
    0
  );
  useEffect(() => {
    if (productId) {
      dispatch(addToCart(productId, qty));
    }
  }, [dispatch, productId, qty]);
  useEffect(() => {
    if (userInfo) {
      setFullName(userInfo.name);
      setEmail(userInfo.email);
    }
    if (!contactPopup) {
      setAlert(0);
    }
  }, [contactPopup]);
  const removeFromCartHandler = (id) => {
    dispatch(removeFromCart(id));
  };

  const checkoutHandler = () => {
    history.push("/login?redirect=shipping");
  };

  const buttonHandlerContinueShopping = () => {
    //history.push("/products");
    window.history.back();
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setAlert(1);
    try {
      const data = JSON.stringify({
        fullName: fullName,
        email: email,
        subject: subject,
        message: message,
        requestType: requestType,
      });
      const res = await axiosInstance.post("/contacts", data);
      if (res.status === 201) {
        setFullName("");
        setEmail("");
        setSubject("");
        setMessage("");
        setAlert(2);
      } else {
        setAlert(3);
      }
    } catch (err) {
      setAlert(3);
    }
  };
  let showAlert = "";
  if (alert == 1) {
    showAlert = <p>Submitting ...</p>;
  } else if (alert == 2) {
    showAlert = (
      <div role="alert" class="fade alert alert-success show">
        Danke uns zu kontaktieren
      </div>
    );
  } else if (alert == 3) {
    showAlert = (
      <div role="alert" class="fade alert alert-danger show">
        Some error occurred !
      </div>
    );
  } else {
    showAlert = "";
  }
  return (
    <Layout
      pageClass="cart"
      title="Cart"
      description="Omas ecommerce online"
      keywords="omas"
      noHeaderBg="true"
    >
      <section className="without-hero-sections">
        <div className="container">
          <div className="bread-crumbs">
            <ul>
              <li>
                <Link to="/">home</Link>
              </li>
              <li>
                <Link to="#">Warenkorb</Link>
              </li>
            </ul>
          </div>

          <div className="cart-page">
            <Row>
              <Col md={8}>
                <h1>Warenkorb</h1>
                {cartItems.length === 0 ? (
                  <Message>
                    Der Warenkorb ist leer <Link to="/">Zurück</Link>
                  </Message>
                ) : (
                  <ListGroup variant="flush">
                    {cartItems.map((item) => {
                      return (
                        <ListGroup.Item key={item.product}>
                          <Row>
                            <Col md={2}>
                              <Image
                                src={`${IMAGE_URL + item.image}`}
                                alt={item.name}
                                fluid
                                rounded
                              />
                            </Col>
                            <Col md={2}>
                              <Link to={`/products/${item.product}`}>
                                {item.name}
                              </Link>
                              <br />
                              Verkäufer: {item.seller}
                            </Col>
                            <Col md={2}>
                              {calculatePrice(
                                item.price,
                                item.discount,
                                item.tax
                              )}
                            </Col>
                            <Col md={2}>
                              <Form.Control
                                as="select"
                                value={item.qty}
                                onChange={(e) =>
                                  dispatch(
                                    addToCart(
                                      item.product,
                                      Number(e.target.value)
                                    )
                                  )
                                }
                              >
                                {item.countInStock < item.maxOrder
                                  ? [...Array(item.countInStock).keys()].map(
                                      (x) => (
                                        <option key={x + 1} value={x + 1}>
                                          {x + 1}
                                        </option>
                                      )
                                    )
                                  : [...Array(item.maxOrder).keys()].map(
                                      (x) => (
                                        <option key={x + 1} value={x + 1}>
                                          {x + 1}
                                        </option>
                                      )
                                    )}
                              </Form.Control>
                            </Col>
                            <Col md={1}>
                              <Link
                                to="#"
                                className="main-list-item-anchor"
                                onClick={() => {
                                  setRequestType(`Bulk Order - ${item.name}`);
                                  setContactPopup(true);
                                }}
                              >
                                Will mehr
                              </Link>
                            </Col>
                            <Col md={2}>
                              {calculatePrice(
                                item.price * item.qty,
                                item.discount,
                                item.tax
                              )}
                            </Col>
                            <Col md={1}>
                              <Button
                                type="button"
                                variant="light"
                                onClick={() =>
                                  removeFromCartHandler(item.product)
                                }
                              >
                                <AiFillDelete />
                              </Button>
                            </Col>
                          </Row>
                        </ListGroup.Item>
                      );
                    })}
                  </ListGroup>
                )}
              </Col>
              <Col md={4}>
                <Card>
                  <ListGroup variant="flush">
                    <ListGroup.Item>
                      <h2>
                        Produkte im Warenkorb (
                        {cartItems.reduce((acc, item) => acc + item.qty, 0)})
                      </h2>
                      <ul className="total-detail-area">
                        {cartItems.map((item, index) => (
                          <li key={`cart-item-${index}`}>
                            <span className="title_area">
                              {item.qty} x {item.name}
                            </span>
                            <span className="price_area">
                              {calculatePrice(
                                item.price * item.qty,
                                item.discount,
                                item.tax
                              )}
                            </span>
                          </li>
                        ))}
                        <li>
                          <span className="title_area">Total*:</span>
                          <span className="price_area">
                            {calculatePrice(cartTotalPrice)}
                          </span>
                        </li>
                        <li>
                          <br />
                          <small style={{ textTransform: "none" }}>
                            *incl Mwst, excl Verpackung und Versand
                          </small>
                        </li>
                      </ul>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Button
                        type="button"
                        className="btn-block"
                        disabled={cartItems.length === 0}
                        onClick={checkoutHandler}
                      >
                        Bestellung fortsetzen
                      </Button>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Button
                        type="button"
                        className="btn-block"
                        onClick={buttonHandlerContinueShopping}
                      >
                        Weiter einkaufen
                      </Button>
                    </ListGroup.Item>
                  </ListGroup>
                </Card>
              </Col>
            </Row>
            {/* <CartItem />
              <CartItem />
              <h4 className="subtotal-title">Subtotal <span>$8.60</span></h4>

              <div className="continue-or">
                <Link to="#">Continue shopping</Link>
                <span>or</span>
                <button type="button" className="cart-common">Update cart</button>
                <span>or</span>
                <button type="button" className="cart-common">Check out</button>
              </div>

              <div className="note-strip">
                <p>Spices process all orders in USD. While the content of your cart is currently displayed in USD, you will checkout using USD at the most current exchange rate.</p>
              </div>

              <h5 className="special-instruction-title">Special instructions of seller</h5>
              <textarea className="special-instruction-text"></textarea>

              <div className="payment-cards">
                <Link to="#"><img src="/assets/images/cc-amex.png" alt="card" /></Link>
                <Link to="#"><img src="/assets/images/cc-discover.png" alt="card" /></Link>
                <Link to="#"><img src="/assets/images/cc-visa.png" alt="card" /></Link>
                <Link to="#"><img src="/assets/images/cc-mastercard.png" alt="card" /></Link>
                <Link to="#"><img src="/assets/images/cc-maestro.png" alt="card" /></Link>
                <Link to="#"><img src="/assets/images/cc-cirrus.png" alt="card" /></Link>
                <Link to="#"><img src="/assets/images/cc-paypal.png" alt="card" /></Link>
                <Link to="#"><img src="/assets/images/cc-google.png" alt="card" /></Link>
              </div> */}
          </div>
        </div>
      </section>

      <div className="form-popup">
        <Popup trigger={contactPopup} setTrigger={setContactPopup}>
          <h4 className="slider-title">Kontakt zu Omas-Laden:</h4>
          <p>
            E-mail: <a href="mailto:mail@omas-laden.at">mail@omas-laden.at</a>
          </p>
          <p>
            Telefon: <a href="tel: +43 699 14107 207"> +43 699 14107 207 </a>
          </p>
          {showAlert}
          <h5>
            <span>Oder: On-Line Formular</span>
          </h5>
          <form onSubmit={handleSubmit}>
            <label>User Name</label>
            {userInfo ? (
              <p>{userInfo.name}</p>
            ) : (
              <input
                type="text"
                value={fullName}
                placeholder=""
                onChange={(e) => setFullName(e.target.value)}
              />
            )}
            <label>Email</label>
            {userInfo ? (
              <p>{userInfo.email}</p>
            ) : (
              <input
                type="text"
                value={email}
                placeholder=""
                onChange={(e) => setEmail(e.target.value)}
              />
            )}
            <label>Betreff</label>
            <input
              type="text"
              value={subject}
              placeholder=""
              onChange={(e) => setSubject(e.target.value)}
            />
            <label>Nachricht</label>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <button type="submit" className="submit_btn">
              Absenden
            </button>
            <a
              type="button"
              className="cancel_btn"
              onClick={() => setContactPopup(false)}
            >
              Abbrechen
            </a>
          </form>
        </Popup>
      </div>
    </Layout>
  );
};

export default Cart;

const CartItem = () => {
  return (
    <>
      <div className="cart-item-box">
        <div className="cart-item-image">
          <img
            src="/assets/images/loose.png"
            alt="img"
            className="img-responsive"
          />
        </div>
        <div className="cart-item-details">
          <h3 className="cart-item-details-title">
            Celery seed ground
            <span>SPICES</span>
          </h3>
          <div className="price-quantity">
            <h3 className="cart-price">$6.20</h3>
            <div className="quantity">
              <label>Qty:</label>
              <input type="text" value="1" />
            </div>
          </div>
          <div className="total-price">
            <h4 className="total">
              Total: <span>$2.40</span>
            </h4>
          </div>
        </div>
      </div>
    </>
  );
};
