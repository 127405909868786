import React from "react";
import {IoMdClose} from "react-icons/io";
import './Popup.scss';

const Popup = (props) => {
  return (props.trigger) ? (
    <>
      <div className="react-popup">
          <div className="react-popup-inner">
              <button type="button" className="react-popup-close" onClick={() => props.setTrigger(false)}><IoMdClose /></button>
              { props.children }
          </div>
      </div>
    </>
  ) : "";
};

export default Popup;
