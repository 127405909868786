export const PRODUCT_IMAGE_LIST_REQUEST = "PRODUCT_IMAGE_LIST_REQUEST";
export const PRODUCT_IMAGE_LIST_SUCCESS = "PRODUCT_IMAGE_LIST_SUCCESS";
export const PRODUCT_IMAGE_LIST_FAIL = "PRODUCT_IMAGE_LIST_FAIL";

export const PRODUCT_IMAGE_DETAILS_REQUEST = "PRODUCT_IMAGE_DETAILS_REQUEST";
export const PRODUCT_IMAGE_DETAILS_SUCCESS = "PRODUCT_IMAGE_DETAILS_SUCCESS";
export const PRODUCT_IMAGE_DETAILS_FAIL = "PRODUCT_IMAGE_DETAILS_FAIL";

export const PRODUCT_IMAGE_DELETE_REQUEST = "PRODUCT_IMAGE_DELETE_REQUEST";
export const PRODUCT_IMAGE_DELETE_SUCCESS = "PRODUCT_IMAGE_DELETE_SUCCESS";
export const PRODUCT_IMAGE_DELETE_FAIL = "PRODUCT_IMAGE_DELETE_FAIL";

export const PRODUCT_IMAGE_CREATE_REQUEST = "PRODUCT_IMAGE_CREATE_REQUEST";
export const PRODUCT_IMAGE_CREATE_SUCCESS = "PRODUCT_IMAGE_CREATE_SUCCESS";
export const PRODUCT_IMAGE_CREATE_FAIL = "PRODUCT_IMAGE_CREATE_FAIL";
export const PRODUCT_IMAGE_CREATE_RESET = "PRODUCT_IMAGE_CREATE_RESET";

export const PRODUCT_IMAGE_UPDATE_REQUEST = "PRODUCT_IMAGE_UPDATE_REQUEST";
export const PRODUCT_IMAGE_UPDATE_SUCCESS = "PRODUCT_IMAGE_UPDATE_SUCCESS";
export const PRODUCT_IMAGE_UPDATE_FAIL = "PRODUCT_IMAGE_UPDATE_FAIL";
export const PRODUCT_IMAGE_UPDATE_RESET = "PRODUCT_IMAGE_UPDATE_RESET";

export const PRODUCT_IMAGE_CREATE_REVIEW_REQUEST =
  "PRODUCT_IMAGE_CREATE_REVIEW_REQUEST";
export const PRODUCT_IMAGE_CREATE_REVIEW_SUCCESS =
  "PRODUCT_IMAGE_CREATE_REVIEW_SUCCESS";
export const PRODUCT_IMAGE_CREATE_REVIEW_FAIL =
  "PRODUCT_IMAGE_CREATE_REVIEW_FAIL";
export const PRODUCT_IMAGE_CREATE_REVIEW_RESET =
  "PRODUCT_IMAGE_CREATE_REVIEW_RESET";

export const PRODUCT_IMAGE_TOP_REQUEST = "PRODUCT_IMAGE_TOP_REQUEST";
export const PRODUCT_IMAGE_TOP_SUCCESS = "PRODUCT_IMAGE_TOP_SUCCESS";
export const PRODUCT_IMAGE_TOP_FAIL = "PRODUCT_IMAGE_TOP_FAIL";
