export const SELLER_LIST_REQUEST = "SELLER_LIST_REQUEST";
export const SELLER_LIST_SUCCESS = "SELLER_LIST_SUCCESS";
export const SELLER_LIST_FAIL = "SELLER_LIST_FAIL";

export const SELLER_DETAILS_REQUEST = "SELLER_DETAILS_REQUEST";
export const SELLER_DETAILS_SUCCESS = "SELLER_DETAILS_SUCCESS";
export const SELLER_DETAILS_FAIL = "SELLER_DETAILS_FAIL";

export const SELLER_DELETE_REQUEST = "SELLER_DELETE_REQUEST";
export const SELLER_DELETE_SUCCESS = "SELLER_DELETE_SUCCESS";
export const SELLER_DELETE_FAIL = "SELLER_DELETE_FAIL";

export const SELLER_CREATE_REQUEST = "SELLER_CREATE_REQUEST";
export const SELLER_CREATE_SUCCESS = "SELLER_CREATE_SUCCESS";
export const SELLER_CREATE_FAIL = "SELLER_CREATE_FAIL";
export const SELLER_CREATE_RESET = "SELLER_CREATE_RESET";

export const SELLER_UPDATE_REQUEST = "SELLER_UPDATE_REQUEST";
export const SELLER_UPDATE_SUCCESS = "SELLER_UPDATE_SUCCESS";
export const SELLER_UPDATE_FAIL = "SELLER_UPDATE_FAIL";
export const SELLER_UPDATE_RESET = "SELLER_UPDATE_RESET";

export const SELLER_CREATE_REVIEW_REQUEST = "SELLER_CREATE_REVIEW_REQUEST";
export const SELLER_CREATE_REVIEW_SUCCESS = "SELLER_CREATE_REVIEW_SUCCESS";
export const SELLER_CREATE_REVIEW_FAIL = "SELLER_CREATE_REVIEW_FAIL";
export const SELLER_CREATE_REVIEW_RESET = "SELLER_CREATE_REVIEW_RESET";

export const SELLER_TOP_REQUEST = "SELLER_TOP_REQUEST";
export const SELLER_TOP_SUCCESS = "SELLER_TOP_SUCCESS";
export const SELLER_TOP_FAIL = "SELLER_TOP_FAIL";
