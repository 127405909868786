import React from 'react';
import Layout from '../components/Layout';
import './error.scss';

const Error = () => {
  return (
        <section className='under-construction'>
            <div className="container">
                <img src='/assets/images/maintanance.jpg' alt='maintanance' />
                <h3>We are upgrading</h3>
                <h4>This website under Maintenance !</h4>
                <p>We are running some awsome updates in our site. Please come back later. Have a nice day !</p>
            </div>
        </section>
  )
}

export default Error;