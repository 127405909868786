import React from 'react';
import Layout from '../components/Layout';
import './Privacy.scss';

const BecomeASeller = () => {
  return (
    <Layout
      pageClass="privacy"
      title="Verkäufer werden"
      description="Omas ecommerce online"
      keywords="omas"
      noHeaderBg="true"
    >
      <section className="privacy-policy">
        <div className="container">
          <h1>Verkäufer werden</h1>
          <p>
            Wenn Sie Ihrer Produkte auf omas-laden.at verkaufen wollen, rufen
            Sie mich bitte an, um sämtliche.
          <br/>
            Details zu klären<br/>
            <strong><a href="tel:+4369914107207">+43 699 14107 207</a></strong>
          </p>
        </div>
      </section>
    </Layout>
  );
};

export default BecomeASeller;
