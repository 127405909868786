import React from "react";
import Currency from "react-currency-formatter";

export function calculatePrice(
  price,
  discount = 0,
  tax = 0,
  quantity = 1,
  currency = true
) {
  let newPrice = parseFloat(price);
  if (discount > 0) {
    newPrice = newPrice - newPrice * (discount / 100);
  }
  // if (tax > 0) {
  //     newPrice = newPrice + (newPrice * (tax / 100))
  // }
  return "€" + newPrice.toFixed(2).replace(".", ",").toLocaleString("de-AT");
}

export function calculateTotalPrice(items) {
  let totalPrice = 0;
  items.map(({ price, discount = 0, tax = 0, qty }, index) => {
    const itemPrice = calculatePrice(price, discount, tax, qty, false);
    totalPrice += itemPrice;
  });
  return "€" + totalPrice.toFixed(2).replace(".", ",").toLocaleString("de-AT");
}
