import {
  SELLER_LIST_REQUEST,
  SELLER_LIST_SUCCESS,
  SELLER_LIST_FAIL,
  SELLER_DETAILS_REQUEST,
  SELLER_DETAILS_SUCCESS,
  SELLER_DETAILS_FAIL,
  SELLER_DELETE_REQUEST,
  SELLER_DELETE_SUCCESS,
  SELLER_DELETE_FAIL,
  SELLER_CREATE_RESET,
  SELLER_CREATE_FAIL,
  SELLER_CREATE_SUCCESS,
  SELLER_CREATE_REQUEST,
  SELLER_UPDATE_REQUEST,
  SELLER_UPDATE_SUCCESS,
  SELLER_UPDATE_FAIL,
  SELLER_UPDATE_RESET,
  SELLER_CREATE_REVIEW_REQUEST,
  SELLER_CREATE_REVIEW_SUCCESS,
  SELLER_CREATE_REVIEW_FAIL,
  SELLER_CREATE_REVIEW_RESET,
  SELLER_TOP_REQUEST,
  SELLER_TOP_SUCCESS,
  SELLER_TOP_FAIL,
} from "../constants/sellerConstants";

export const sellerListReducer = (state = { sellers: [] }, action) => {
  switch (action.type) {
    case SELLER_LIST_REQUEST:
      return { loading: true, sellers: [] };
    case SELLER_LIST_SUCCESS:
      return {
        loading: false,
        sellers: action.payload.sellers,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case SELLER_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const sellerDetailsReducer = (
  state = { seller: { reviews: [] } },
  action
) => {
  switch (action.type) {
    case SELLER_DETAILS_REQUEST:
      return { ...state, loading: true };
    case SELLER_DETAILS_SUCCESS:
      return { loading: false, seller: action.payload };
    case SELLER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const sellerDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case SELLER_DELETE_REQUEST:
      return { loading: true };
    case SELLER_DELETE_SUCCESS:
      return { loading: false, success: true };
    case SELLER_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const sellerCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case SELLER_CREATE_REQUEST:
      return { loading: true };
    case SELLER_CREATE_SUCCESS:
      return { loading: false, success: true, seller: {} };
    case SELLER_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case SELLER_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const sellerUpdateReducer = (state = { seller: {} }, action) => {
  switch (action.type) {
    case SELLER_UPDATE_REQUEST:
      return { loading: true };
    case SELLER_UPDATE_SUCCESS:
      return { loading: false, success: true, seller: action.payload };
    case SELLER_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case SELLER_UPDATE_RESET:
      return { seller: {} };
    default:
      return state;
  }
};

export const sellerReviewCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case SELLER_CREATE_REVIEW_REQUEST:
      return { loading: true };
    case SELLER_CREATE_REVIEW_SUCCESS:
      return { loading: false, success: true };
    case SELLER_CREATE_REVIEW_FAIL:
      return { loading: false, error: action.payload };
    case SELLER_CREATE_REVIEW_RESET:
      return {};
    default:
      return state;
  }
};

export const sellerTopRatedReducer = (state = { sellers: [] }, action) => {
  switch (action.type) {
    case SELLER_TOP_REQUEST:
      return { loading: true, sellers: [] };
    case SELLER_TOP_SUCCESS:
      return { loading: false, sellers: action.payload };
    case SELLER_TOP_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
