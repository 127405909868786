import { axiosInstance } from "../utils/axiosInstance";
import {
  GALLERY_LIST_REQUEST,
  GALLERY_LIST_SUCCESS,
  GALLERY_LIST_FAIL,
  GALLERY_DETAILS_REQUEST,
  GALLERY_DETAILS_SUCCESS,
  GALLERY_DETAILS_FAIL,
  GALLERY_DELETE_SUCCESS,
  GALLERY_DELETE_REQUEST,
  GALLERY_DELETE_FAIL,
  GALLERY_CREATE_REQUEST,
  GALLERY_CREATE_SUCCESS,
  GALLERY_CREATE_FAIL,
  GALLERY_UPDATE_REQUEST,
  GALLERY_UPDATE_SUCCESS,
  GALLERY_UPDATE_FAIL,
  GALLERY_CREATE_REVIEW_REQUEST,
  GALLERY_CREATE_REVIEW_SUCCESS,
  GALLERY_CREATE_REVIEW_FAIL,
  GALLERY_TOP_REQUEST,
  GALLERY_TOP_SUCCESS,
  GALLERY_TOP_FAIL,
} from "../constants/galleryConstants";
import { logout } from "./userActions";

export const listAllGalleries = () => async (dispatch) => {
  try {
    dispatch({ type: GALLERY_LIST_REQUEST });

    const { data } = await axiosInstance.get(`/galleries`);

    dispatch({
      type: GALLERY_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GALLERY_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listGalleries =
  (keyword = "", pageNumber = "") =>
  async (dispatch) => {
    try {
      dispatch({ type: GALLERY_LIST_REQUEST });

      const { data } = await axiosInstance.get(
        `/galleries?keyword=${keyword}&pageNumber=${pageNumber}`
      );

      dispatch({
        type: GALLERY_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GALLERY_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listGalleryDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: GALLERY_DETAILS_REQUEST });

    const { data } = await axiosInstance.get(`/galleries/${id}`);

    dispatch({
      type: GALLERY_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GALLERY_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteGallery = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GALLERY_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    // const config = {
    //   headers: {
    //     Authorization: `Bearer ${userInfo.token}`,
    //   },
    // };
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${userInfo.token}`;
    await axiosInstance.delete(`/galleries/${id}`);

    dispatch({
      type: GALLERY_DELETE_SUCCESS,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: GALLERY_DELETE_FAIL,
      payload: message,
    });
  }
};

export const createGallery = (gallery) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GALLERY_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    // const config = {
    //   headers: {
    //     Authorization: `Bearer ${userInfo.token}`,
    //   },
    // };
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${userInfo.token}`;
    const { data } = await axiosInstance.post(`/galleries`, gallery);

    dispatch({
      type: GALLERY_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: GALLERY_CREATE_FAIL,
      payload: message,
    });
  }
};

export const updateGallery = (gallery) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GALLERY_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    // const config = {
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${userInfo.token}`,
    //   },
    // };
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${userInfo.token}`;
    const { data } = await axiosInstance.put(`/galleries/${gallery._id}`,gallery);

    dispatch({
      type: GALLERY_UPDATE_SUCCESS,
      payload: data,
    });
    dispatch({ type: GALLERY_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: GALLERY_UPDATE_FAIL,
      payload: message,
    });
  }
};

export const createGalleryReview =
  (galleryId, review) => async (dispatch, getState) => {
    try {
      dispatch({
        type: GALLERY_CREATE_REVIEW_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      // const config = {
      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: `Bearer ${userInfo.token}`,
      //   },
      // };
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${userInfo.token}`;
      await axiosInstance.post(`/galleries/${galleryId}/reviews`, review);

      dispatch({
        type: GALLERY_CREATE_REVIEW_SUCCESS,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Not authorized, token failed") {
        dispatch(logout());
      }
      dispatch({
        type: GALLERY_CREATE_REVIEW_FAIL,
        payload: message,
      });
    }
  };

export const listTopGalleries = () => async (dispatch) => {
  try {
    dispatch({ type: GALLERY_TOP_REQUEST });

    const { data } = await axiosInstance.get(`/galleries/top`);

    dispatch({
      type: GALLERY_TOP_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GALLERY_TOP_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
