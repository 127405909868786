import React, { useEffect } from "react";
import { APP_ROOT } from "../../config/config";
import "./Best.scss";

const Best = () => {
  return (
    <>
      <section className="best-section">
        <div className="best-image">
          <img
            src={`${APP_ROOT + "/assets/images/bg-image.jpg"}`}
            alt="best-hero"
            className="img-responsive"
          />
        </div>

        <div className="best-text">
          <div className="container">
            <h3 className="best-title">Bilderstrecken</h3>
            <h4 className="best-subtitle">
              Bilder vom Fertigungsprozess verschiedener Anbieter
            </h4>
          </div>
        </div>
      </section>
    </>
  );
};

export default Best;
