import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../components/Layout";
import ProductItemDetails from "../components/ProductDetail/ProductItemDetails";
import Slider from "../components/ProductDetail/Slider";
import { axiosInstance } from "../utils/axiosInstance";
import { useParams } from "react-router";
import { listProductDetails } from "../actions/productActions";

const ProductDetails = ({ history }) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const productDetails = useSelector((state) => state.productDetails);
  const { loading, error, product } = productDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!product._id || product._id !== id) {
      dispatch(listProductDetails(id));
    }
  }, [dispatch]);

  return (
    <Layout
      pageClass="product-detail"
      title="Product details"
      description="Omas ecommerce online"
      keywords="omas"
      noHeaderBg="true"
    >
      <section className="without-hero-sections">
        <div className="container">
          <div className="bread-crumbs">
            <ul>
              <li>
                <Link to="/">home</Link>
              </li>
              <li>
                <Link to="#">Produkt</Link>
              </li>
            </ul>
          </div>
          <div className="product-detail-section">
            {product?.product &&
              (product.productImages.length > 0 || product.product?.image) && (
                <Slider
                  title={product?.product?.name}
                  featuredImage={product.product.image}
                  images={product.productImages}
                />
              )}
            {loading && <h2>Loading...</h2>}
            {product?.product && (
              <ProductItemDetails productDetails={product} />
            )}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ProductDetails;
