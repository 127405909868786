import React from "react";
import { parseISO, format } from "date-fns";
import { Link } from "react-router-dom";
import { BiTimeFive } from "react-icons/bi";
import { FaArrowRight } from "react-icons/fa";
import { APP_ROOT, IMAGE_URL } from "../../config/config";
import "./Blog.scss";

const Blog = ({ blogItems }) => {
  const { createdAt, description, title, _id, image } = blogItems;
  // const formattedDate = format(parseISO(createdAt), 'MM/dd/yyyy');
  return (
    <div style={{ marginBottom: "25px" }}>
      <div className="blog-box">
        <div className="blog-image">
          {image ? (
            <img
              src={IMAGE_URL + image}
              alt="blog"
              className="img-responsive"
            />
          ) : (
            <img
              src={`${APP_ROOT + "/assets/images/no-image.png"}`}
              alt="blog"
              className="img-responsive"
            />
          )}
        </div>
        <div className="blog-text">
          {/* <h3 className="date-blog"><span><BiTimeFive /></span>{formattedDate}</h3> */}
          <h4 className="blog-title">{title}</h4>
          <p className="blog-description">{description}</p>
          <Link to={`/galleries/${_id}`} className="read-arrow">
            Fotos ansehen <FaArrowRight />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Blog;
