import React from "react";
import { Parallax } from "react-scroll-parallax";
import { APP_ROOT } from "../../config/config";
import "./Banner.scss";

const Banner = () => {
  return (
    <section className="home-hero">
      <div className="hero-image">
        <img
          src={`${APP_ROOT + "/assets/images/omas-hero.jpg"}`}
          alt="home-hero"
          className="img-responsive"
        />
      </div>

      <div className="hero-text">
        <div className="container">
          <h1 className="hero-main-title">Omas Laden</h1>
          <h2 className="hero-sub-title">
            Regionale Produkte aus dem Süden Österreiches
          </h2>
          <p className="hero-description">
            Garten und Feldprodukte von regionalen Anbietern.
            <br />
            Wir verwenden keine Cookies
            <br />
            <small>Die Webseite wurde vom Land Burgenland gefördert</small>
          </p>
        </div>
      </div>
    </section>
  );
};

export default Banner;
