import { axiosInstance } from "../utils/axiosInstance";
import {
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  CART_SAVE_SHIPPING_ADDRESS,
  CART_SAVE_PAYMENT_METHOD,
} from "../constants/cartConstants";

export const addToCart = (id, qty) => async (dispatch, getState) => {
  const {
    data: { product },
  } = await axiosInstance.get(`/products/${id}`);
  let newPrice = parseFloat(product.price);
  if (product.discount > 0) {
    newPrice = newPrice - newPrice * (product.discount / 100);
  }
  // if (product.tax > 0) {
  //   newPrice = newPrice + newPrice * (product.tax / 100);
  // }
  newPrice = newPrice.toFixed(2);
  dispatch({
    type: CART_ADD_ITEM,
    payload: {
      product: product._id,
      name: product.name,
      image: product.image,
      price: newPrice,
      maxOrder: product.maxOrderQuantity,
      countInStock: product.countInStock,
      qty,
      seller: product.seller.sAlias,
    },
  });

  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
};

export const removeFromCart = (id) => (dispatch, getState) => {
  dispatch({
    type: CART_REMOVE_ITEM,
    payload: id,
  });

  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
};

export const saveShippingAddress = (data) => (dispatch) => {
  dispatch({
    type: CART_SAVE_SHIPPING_ADDRESS,
    payload: data,
  });

  localStorage.setItem("shipping", JSON.stringify(data));
};

export const savePaymentMethod = (data) => (dispatch) => {
  dispatch({
    type: CART_SAVE_PAYMENT_METHOD,
    payload: data,
  });

  localStorage.setItem("paymentMethod", JSON.stringify(data));
};
