import React, { useState, useEffect } from "react";
import { useParams } from 'react-router';
import { useDispatch, useSelector } from "react-redux";
import Layout from "../components/Layout";
import GallerySlider from "../components/Gallery/GallerySlider";
import { listGalleryDetails, } from "../actions/galleryActions";
import { listGalleryImages } from "../actions/galleryImageActions";
import './gallery.scss';

const Gallery = () => {

  const { id } = useParams();
  const [gallery, setGallery] = useState([]);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const galleryDetails = useSelector((state) => state.galleryDetails);
  const {
    loading: loadingGallery,
    error,
    gallery: {
      _id,
      title,
      description,
    },
  } = galleryDetails;

  const galleryImageList = useSelector((state) => state.galleryImageList);
  const {
    loading: loadingGalleryImageList,
    error: errorGalleryImageList,
    galleryImages,
  } = galleryImageList;

  useEffect(() => {
    if (!_id || _id !== id) {
      dispatch(listGalleryDetails(id));
      dispatch(listGalleryImages(id));
    }
  }, [dispatch, id]);

  return (
    <Layout
      pageClass="gallery"
      title="Gallery"
      description="Omas ecommerce online"
      keywords="omas"
      noHeaderBg="true"
    >
      <section className="gallery-section without-hero-sections">
        <div className="container">
          {!loadingGallery && <>
            <h1 className="gallery-title">{title}</h1>
            <p className="gallery-description">{description}</p>
          </>}
          <div className="gallery-image-section">
            {loadingGalleryImageList && <p>Loading...</p>}
            {(!loadingGalleryImageList && galleryImages.length > 0 ) && <GallerySlider images={galleryImages} />}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Gallery;
