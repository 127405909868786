import { axiosInstance } from "../utils/axiosInstance";
import {
  GALLERY_IMAGE_LIST_REQUEST,
  GALLERY_IMAGE_LIST_SUCCESS,
  GALLERY_IMAGE_LIST_FAIL,
  GALLERY_IMAGE_DETAILS_REQUEST,
  GALLERY_IMAGE_DETAILS_SUCCESS,
  GALLERY_IMAGE_DETAILS_FAIL,
  GALLERY_IMAGE_DELETE_SUCCESS,
  GALLERY_IMAGE_DELETE_REQUEST,
  GALLERY_IMAGE_DELETE_FAIL,
  GALLERY_IMAGE_CREATE_REQUEST,
  GALLERY_IMAGE_CREATE_SUCCESS,
  GALLERY_IMAGE_CREATE_FAIL,
  GALLERY_IMAGE_UPDATE_REQUEST,
  GALLERY_IMAGE_UPDATE_SUCCESS,
  GALLERY_IMAGE_UPDATE_FAIL,
  GALLERY_IMAGE_CREATE_REVIEW_REQUEST,
  GALLERY_IMAGE_CREATE_REVIEW_SUCCESS,
  GALLERY_IMAGE_CREATE_REVIEW_FAIL,
  GALLERY_IMAGE_TOP_REQUEST,
  GALLERY_IMAGE_TOP_SUCCESS,
  GALLERY_IMAGE_TOP_FAIL,
} from "../constants/galleryImageConstants";
import { logout } from "./userActions";
import { wrapMergePropsFunc } from "react-redux/lib/connect/mergeProps";

export const listGalleryImages =
  (gallery = "", pageNumber = "") =>
  async (dispatch) => {
    try {
      dispatch({ type: GALLERY_IMAGE_LIST_REQUEST });

      const { data } = await axiosInstance.get(`/gallery-images?gallery=${gallery}`);

      dispatch({
        type: GALLERY_IMAGE_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GALLERY_IMAGE_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listGalleryImageDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: GALLERY_IMAGE_DETAILS_REQUEST });

    const { data } = await axiosInstance.get(`/gallery-images/?gallery=${id}`);

    dispatch({
      type: GALLERY_IMAGE_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GALLERY_IMAGE_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteGalleryImage = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GALLERY_IMAGE_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    // const config = {
    //   headers: {
    //     Authorization: `Bearer ${userInfo.token}`,
    //   },
    // };
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${userInfo.token}`;
    await axiosInstance.delete(`/gallery-images/${id}`);

    dispatch({
      type: GALLERY_IMAGE_DELETE_SUCCESS,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: GALLERY_IMAGE_DELETE_FAIL,
      payload: message,
    });
  }
};

export const createGalleryImage =
  (galleryId, galleryImage) => async (dispatch, getState) => {
    try {
      dispatch({
        type: GALLERY_IMAGE_CREATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      // const config = {
      //   headers: {
      //     Authorization: `Bearer ${userInfo.token}`,
      //   },
      // };
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${userInfo.token}`;
      const { data } = await axiosInstance.post(`/gallery-images/${galleryId}`,galleryImage);

      dispatch({
        type: GALLERY_IMAGE_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Not authorized, token failed") {
        dispatch(logout());
      }
      dispatch({
        type: GALLERY_IMAGE_CREATE_FAIL,
        payload: message,
      });
    }
  };

export const updateGalleryImage =
  (galleryImageData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: GALLERY_IMAGE_UPDATE_REQUEST,
      });

      const { galleryId, updatedGalleryImageFiles } = galleryImageData;

      const {
        userLogin: { userInfo },
      } = getState();

      // const config = {
      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: `Bearer ${userInfo.token}`,
      //   },
      // };
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${userInfo.token}`;
      const { data } = await axiosInstance.put(`/gallery-images/${galleryId}`,{ images: updatedGalleryImageFiles });

      dispatch({
        type: GALLERY_IMAGE_UPDATE_SUCCESS,
        payload: data,
      });
      dispatch({ type: GALLERY_IMAGE_DETAILS_SUCCESS, payload: data });
    } catch (error) {
      console.log(error, "err");
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Not authorized, token failed") {
        dispatch(logout());
      }
      dispatch({
        type: GALLERY_IMAGE_UPDATE_FAIL,
        payload: message,
      });
    }
  };

export const updateGalleryImageSingle =
  (galleryId, galleryImageData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: GALLERY_IMAGE_UPDATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      // const config = {
      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: `Bearer ${userInfo.token}`,
      //   },
      // };
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${userInfo.token}`;
      const { data } = await axiosInstance.patch(`/gallery-images/${galleryId}`,{ image: galleryImageData });

      dispatch({
        type: GALLERY_IMAGE_UPDATE_SUCCESS,
        payload: data,
      });
      dispatch({ type: GALLERY_IMAGE_DETAILS_SUCCESS, payload: data });
    } catch (error) {
      console.log(error, "err");
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Not authorized, token failed") {
        dispatch(logout());
      }
      dispatch({
        type: GALLERY_IMAGE_UPDATE_FAIL,
        payload: message,
      });
    }
  };
