import {
  GALLERY_IMAGE_LIST_REQUEST,
  GALLERY_IMAGE_LIST_SUCCESS,
  GALLERY_IMAGE_LIST_FAIL,
  GALLERY_IMAGE_DETAILS_REQUEST,
  GALLERY_IMAGE_DETAILS_SUCCESS,
  GALLERY_IMAGE_DETAILS_FAIL,
  GALLERY_IMAGE_DELETE_REQUEST,
  GALLERY_IMAGE_DELETE_SUCCESS,
  GALLERY_IMAGE_DELETE_FAIL,
  GALLERY_IMAGE_CREATE_RESET,
  GALLERY_IMAGE_CREATE_FAIL,
  GALLERY_IMAGE_CREATE_SUCCESS,
  GALLERY_IMAGE_CREATE_REQUEST,
  GALLERY_IMAGE_UPDATE_REQUEST,
  GALLERY_IMAGE_UPDATE_SUCCESS,
  GALLERY_IMAGE_UPDATE_FAIL,
  GALLERY_IMAGE_UPDATE_RESET,
} from "../constants/galleryImageConstants";

export const galleryImageListReducer = (
  state = { galleryImages: [] },
  action
) => {
  switch (action.type) {
    case GALLERY_IMAGE_LIST_REQUEST:
      return { loading: true, galleryImages: [] };
    case GALLERY_IMAGE_LIST_SUCCESS:
      return {
        loading: false,
        galleryImages: action.payload.galleryImages,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case GALLERY_IMAGE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const galleryImageDetailsReducer = (
  state = { galleryImage: { reviews: [] } },
  action
) => {
  switch (action.type) {
    case GALLERY_IMAGE_DETAILS_REQUEST:
      return { ...state, loading: true };
    case GALLERY_IMAGE_DETAILS_SUCCESS:
      return { loading: false, galleryImage: action.payload };
    case GALLERY_IMAGE_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const galleryImageDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case GALLERY_IMAGE_DELETE_REQUEST:
      return { loading: true };
    case GALLERY_IMAGE_DELETE_SUCCESS:
      return { loading: false, success: true };
    case GALLERY_IMAGE_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const galleryImageCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case GALLERY_IMAGE_CREATE_REQUEST:
      return { loading: true };
    case GALLERY_IMAGE_CREATE_SUCCESS:
      return { loading: false, success: true, galleryImage: action.payload };
    case GALLERY_IMAGE_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case GALLERY_IMAGE_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const galleryImageUpdateReducer = (
  state = { galleryImage: {} },
  action
) => {
  switch (action.type) {
    case GALLERY_IMAGE_UPDATE_REQUEST:
      return { loading: true };
    case GALLERY_IMAGE_UPDATE_SUCCESS:
      return { loading: false, success: true, galleryImage: action.payload };
    case GALLERY_IMAGE_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case GALLERY_IMAGE_UPDATE_RESET:
      return { galleryImage: {} };
    default:
      return state;
  }
};
