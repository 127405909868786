import React from "react";
import { Link } from "react-router-dom";
import { CgSmileSad } from "react-icons/cg";
import Layout from "../components/Layout";
import "./NotFoundPage.scss";

const NotFoundPage = () => {
  return (
    <>
      <Layout
        pageClass="notfound"
        title="Home"
        description="Omas ecommerce online"
        keywords="omas"
        noHeaderBg="true"
      >
        <section className="error-page">
          <div className="container">
            <h2>
              <CgSmileSad />
            </h2>
            <h3>404</h3>
            <h4>Page not found</h4>
            <p>
              The Page you are looking for doesn't exist or an other error
              occurred <Link to="/">Zurück</Link>
            </p>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default NotFoundPage;
