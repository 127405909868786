import React from "react";
import Layout from "../components/Layout";
import "./Privacy.scss";

const Disclaimer = () => {
  return (
    <Layout
      pageClass="privacy"
      title="Haftungsaussluss"
      description="Omas ecommerce online"
      keywords="omas"
      noHeaderBg="true"
    >
      <section className="privacy-policy">
        <div className="container">
          <h1>Haftungsaussluss</h1>
          <p>
            Trotz sorgfältiger inhaltlicher Kontrolle übernimmt der
            Webseitenbetreiber (von omas-laden.at, Martin Nemeth) keine Haftung
            für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten
            sind ausschließlich deren Betreiber verantwortlich. Sollten Sie
            dennoch auf ausgehende Links aufmerksam werden, welche auf eine
            Webseite mit rechtswidriger Tätigkeit/Information verweisen,
            ersuchen wir um dementsprechenden Hinweis (
            <a href="mailto:gruh.gruh@bnet.at">gruh.gruh@bnet.at</a>), um diesen
            nach § 17 Abs. 2 ECG umgehend zu entfernen.
          </p>
          <p>
            Die Urheberrechte Dritter werden vom Betreiber mit bestmöglicher
            Sorgfalt beachtet. Sollten Sie trotzdem auf eine
            Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
            entsprechenden Hinweis an (
            <a href="mailto:gruh.gruh@bnet.at">gruh.gruh@bnet.at</a>). Bei
            Bekanntwerden derartiger Rechtsverletzungen werden wir den
            betroffenen Inhalt umgehend entfernen.
          </p>
          <p>
            Quelle:
            <a href="https://fairesRecht.at">
              Rechtsanwalt finden - fairesRecht
            </a>
          </p>
        </div>
      </section>
    </Layout>
  );
};

export default Disclaimer;
