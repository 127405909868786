import React from "react";
import Layout from "../components/Layout";
import "./Privacy.scss";

const Privacy = () => {
  return (
    <Layout
      pageClass="privacy"
      title="Privacy policy"
      description="Omas ecommerce online"
      keywords="omas"
      noHeaderBg="true"
    >
        <section className="privacy-policy">
            <div className="container">
                <h1>Privacy policy</h1>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vitae aliquet velit, ac gravida nisi. Suspendisse varius egestas varius. Nulla pellentesque tempus sem ut ultrices. Ut sodales faucibus ante non tristique. Nunc condimentum nunc eget sapien iaculis, eget suscipit arcu consectetur. Proin imperdiet laoreet elit nec consectetur. Sed eget mi tortor. Quisque in congue dui, vel ultrices orci. Duis tincidunt nunc sit amet leo pretium, ut pulvinar velit pretium. Nam efficitur magna vitae nisi consectetur posuere.</p>

                <ul className="dot">
                    <li>list one here</li>
                    <li>list one here</li>
                    <li>list one here</li>
                    <li>list one here</li>
                </ul>

                <ul className="numeric">
                    <li>list one here</li>
                    <li>list one here</li>
                    <li>list one here</li>
                    <li>list one here</li>
                </ul>

                <ul className="alpha">
                    <li>list one here</li>
                    <li>list one here</li>
                    <li>list one here</li>
                    <li>list one here</li>
                </ul>
            </div>
        </section>
    </Layout>
  );
};

export default Privacy;
