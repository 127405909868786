import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form, Button, Row, Col, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Common/Message";
import Loader from "../components/Common/Loader";
import { passwordTokenVerify, resetPassword } from "../actions/userActions";
import Layout from "../components/Layout";

const PasswordReset = ({ location, history, match }) => {
  const token = match.params.token;

  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState(null);

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const passwordVerifyToken = useSelector((state) => state.passwordTokenVerify);
  const { loading, error: verifyTokenError } = passwordVerifyToken;

  const passwordReset = useSelector((state) => state.passwordReset);
  const { success } = passwordReset;
  const redirect = location.search ? location.search.split("=")[1] : "/";

  useEffect(() => {
    if (userInfo) {
      history.push(redirect);
    }
    if (success) {
      history.push("/login");
    }
    if (token) {
      dispatch(passwordTokenVerify(token));
    }
  }, [history, userInfo, redirect, dispatch, success]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage("Passwords do not match");
    } else {
      dispatch(resetPassword(token, password));
    }
  };
  const resetForm = verifyTokenError ? (
    <Message variant="danger">{verifyTokenError}</Message>
  ) : (
    <>
      <p>Please choose your new password:</p>
      <Form onSubmit={submitHandler}>
        <Form.Group controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="confirmPassword">
          <Form.Label> Passwort bestätigen</Form.Label>
          <Form.Control
            type="password"
            placeholder="Passwort bestätigen"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Button type="submit" variant="primary">
          Reset
        </Button>
      </Form>
    </>
  );
  return (
    <Layout
      pageClass="products"
      title="Password Reset"
      description="Omas ecommerce online"
      keywords="omas"
      noHeaderBg="true"
    >
      <section className="without-hero-sections">
        <Container>
          <Row className="justify-content-md-center">
            <Col xs={12} md={9}>
              <h1>Password Reset</h1>
              {loading && <Loader />}
              {message && <Message variant="danger">{message}</Message>}
              {resetForm}
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default PasswordReset;
