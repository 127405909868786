export function customOrderId(cDate) {
  const dat = new Date(cDate);
  const timezoneDate = dat.toLocaleString("de-AT", {
    timeZone: "Europe/Vienna",
  });
  const timezoneDateTimeArr = timezoneDate.split(", ");
  const timezoneDateArr = timezoneDateTimeArr[0].split(".");
  const timezoneTimeArr = timezoneDateTimeArr[1].split(":");

  const yyyy = timezoneDateArr[2];
  const mm = timezoneDateArr[1];
  const dd = timezoneDateArr[0];
  //const hh = timezoneTimeArr[0];
  const min = timezoneTimeArr[1];
  const sec = timezoneTimeArr[2];
  return yyyy + mm + dd + min + sec;
}
