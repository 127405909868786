import React from "react";
import { Helmet } from "react-helmet";
import Header from "./Header";
import Footer from "./Footer";


const Layout = ({ pageClass, title, titleTail = " | Omas", description, keywords, noHeaderBg, children }) => {
  return (
    <>
      <div className={`page ${pageClass}`}>
        <Helmet>
          <title>{title + titleTail}</title>
          <meta name="description" content={description} />
          <meta name="keywords" content={keywords} />
          <meta name="viewport" content="width=device-width, maximum-scale=1.0, minimum-scale=1.0, initial-scale=1.0"/>
          <link rel="shortcut icon" sizes="16x16 24x24 32x32 64x64" href="/images/favicon.ico" type="image/x-icon" />
        </Helmet>
        <Header noHeaderBg={noHeaderBg} />
        <main className="page-main">{children}</main>
        <Footer />
      </div>
    </>
  );
};

export default Layout;
