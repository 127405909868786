import React from "react";
import Layout from "../components/Layout";
import "./Privacy.scss";

const DataProtection = () => {
  return (
    <Layout
      pageClass="privacy"
      title="Datenschutzerklärung"
      description="Omas ecommerce online"
      keywords="omas"
      noHeaderBg="true"
    >
      <section className="privacy-policy">
        <div className="container">
          <h1>Datenschutzerklärung</h1>
          <h3> Erklärung zur Informationspflicht </h3>
          <br />
          <p>
            <strong>Datenschutzerklärung</strong>
          </p>
          <p>
            In folgender Datenschutzerklärung informieren wir Sie über die
            wichtigsten Aspekte der Datenverarbeitung im Rahmen unserer
            Webseite. Wir erheben und verarbeiten personenbezogene Daten nur auf
            Grundlage der gesetzlichen Bestimmungen (Datenschutzgrundverordnung,
            Telekommunikationsgesetz 2003).
          </p>
          <p>
            Sobald Sie als Benutzer auf unsere Webseite zugreifen oder diese
            besuchen wird Ihre IP-Adresse, Beginn sowie Beginn und Ende der
            Sitzung erfasst. Dies ist technisch bedingt und stellt somit ein
            berechtigtes Interesse iSv Art 6 Abs 1 lit f DSGVO.
          </p>
          <h5>Kontakt mit uns</h5>
          <p>
            Wenn Sie uns, entweder über unser Kontaktformular auf unserer
            Webseite, oder per Email kontaktieren, dann werden die von Ihnen an
            uns übermittelten Daten zwecks Bearbeitung Ihrer Anfrage oder für
            den Fall von weiteren Anschlussfragen für sechs Monate bei uns
            gespeichert. Es erfolgt, ohne Ihre Einwilligung, keine Weitergabe
            Ihrer übermittelten Daten.
          </p>
          <h5> Datenspeicherung </h5>
          <p>
            Im Rahmen der Erleichterung des Einkaufsvorganges und zur späteren
            Vertragsabwicklung werden vom Webshop-Betreiber im Rahmen von
            Cookies die IP-Adresse des Anschlussinhabers gespeichert, ebenso wie
            Name, Rechnungsanschrift, Lieferanschrift, Bestelldetails, Produkt,
            Menge, Preis, Steuersatz, Versanddatum, Rechnungsdatum.
          </p>
          <p>
            Für die Vertragsabwicklung werden auch folgende Daten bei uns
            gespeichert: <br /> Email, Telefonnummer, Name, Adresse, UID wenn
            vorhanden, .
          </p>
          <p>
            Die von Ihnen bereitgestellten Daten sind für die Vertragserfüllung
            und zur Durchführung vorvertraglicher Maßnahmen erforderlich. Ein
            Vertragsabschluss ohne dieser Daten ist nicht möglich. Eine
            Übermittlung der erhobenen Daten an Dritte erfolgt nicht, mit
            Ausnahme der Übermittlung von Zahlungsdaten (Kreditkartendaten) an
            die abwickelnden Bankinstitute/Zahlungsdienstleister zum Zwecke der
            Abbuchung des Einkaufspreises, an das von uns Beauftragte
            Versandunternehmen (Transportunternehmen), welches mit der
            Zustellung der Ware beauftragt ist und unseren Steuerberater zur
            Erfüllung unserer steuerrechtlichen Verpflichtungen.
          </p>
          <p>
            Sollten Sie den Einkaufsvorgang abbrechen, werden diese bei uns
            gespeicherten Daten gelöscht. Solle ein Vertragsabschluss zustande
            kommen, werden sämtliche Daten, resultierend aus dem
            Vertragsverhältnis, bis zum Ablauf der steuerrechtlichen
            Aufbewahrungsfirst (7 Jahre) gespeichert. Der übermittelte Name, die
            Anschrift, gekaufte Waren und Kaufdatum werden darüber hinaus bis
            zum Ablauf der Produkthaftung (10 Jahre) gespeichert. Die
            Datenverarbeitung erfolgt auf Basis der gesetzlichen Bestimmungen
            des § 96 Abs 3 TKG sowie des Art 6 Abs 1 lit a (Einwilligung)
            und/oder lit b (notwendig zur Vertragserfüllung) der DSGVO.
          </p>
          <h5> Server-Log Files </h5>
          <p>
            Diese Webseite und der damit verbundene Provider erhebt im Zuge der
            Webseitennutzung automatisch Informationen im Rahmen sogenannter
            "Server-Log Files". Dies betrifft insbesondere:
          </p>
          <ul className="simple-ul">
            <li> IP-Adresse oder Hostname </li>
            <li> den verwendeten Browser </li>
            <li> Aufenthaltsdauer auf der Webseite sowie Datum und Uhrzeit </li>
            <li> aufgerufene Seiten der Webseite </li>
            <li> Spracheinstellungen und Betriebssystem </li>
            <li>
              "Leaving-Page" (auf welcher URL hat der Benutzer die Webseite
              verlassen)
            </li>
            <li> ISP (Internet Service Provider) </li>
          </ul>
          <p>
            Diese erhobenen Informationen werden nicht personenbezogen
            verarbeitet oder mit personenbezogenen Daten in Verbindung gebracht.
          </p>
          <p>
            Der Webseitenbetreiber behält es sich vor, im Falle von
            Bekanntwerden rechtswidriger Tätigkeiten, diese Daten auszuwerten
            oder zu überprüfen.
          </p>
          <h5>Ihre Rechte als Betroffener</h5>
          <p>
            Sie als Betroffener haben bezüglich Ihrer Daten, welche bei uns
            gespeichert sind grundsätzlich ein Recht auf:
          </p>
          <ul className="simple-ul">
            <li>Auskunft</li>
            <li>Löschung der Daten</li>
            <li>Berichtigung der Daten</li>
            <li>Übertragbarkeit der Daten</li>
            <li>Wiederruf und Widerspruch zur Datenverarbeitung</li>
            <li>Einschränkung</li>
          </ul>
          <p>
            Wenn sie vermuten, dass im Zuge der Verarbeitung Ihrer Daten
            Verstöße gegen das Datenschutzrecht passiert sind, so haben Sie die
            Möglichkeit sich bei uns (gruh.gruh@bnet.at) oder der
            Datenschutzbehörde zu beschweren.
          </p>
          <h5>Sie erreichen uns unter folgenden Kontaktdaten:</h5>
          <p>
            <b>Webseitenbetreiber:</b> Martin Nemeth <br />
            <b>Telefonnummer:</b>
            <a href="tel:0699 14107 207"> 0699 14107 207 </a>
            <br />
            <b>Email:</b>
            <a href="mailto:gruh.gruh@bnet.at">gruh.gruh@bnet.at</a>
          </p>
          <p>
            Quelle:
            <b>
              <a href="https://fairesrecht.at/">fairesRecht.at</a>
            </b>
            in Kooperation mit
            <b>
              <a href="https://www.fairedaten.at/">
                Entschädigung für Datenleaks erhalten
              </a>
            </b>
          </p>
        </div>
      </section>
    </Layout>
  );
};

export default DataProtection;
