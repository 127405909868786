import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  Pagination,
  Controller,
  Thumbs,
  Autoplay,
} from "swiper";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import "swiper/swiper-bundle.css";
import { IMAGE_URL } from "../../config/config";

SwiperCore.use([Navigation, Pagination, Controller, Thumbs, Autoplay]);

const PopupSlider = ({ images }) => {
  const [realitySwiper, setrealitySwiper] = useState(null);
  const [controlledRealSwiper, setcontrolledRealSwiper] = useState(null);

  const onNextClick = () => {
    controlledRealSwiper.slideNext(500);
  };

  const onPreviousClick = () => {
    controlledRealSwiper.slidePrev(500);
  };

  return (
    <>
      <div className="product-slider">
        <Swiper
          id="bigSectionImage"
          className="big-images"
          thumbs={{ swiper: realitySwiper }}
          controller={{ control: controlledRealSwiper }}
          onSwiper={setcontrolledRealSwiper}
          autoplay={{
            delay: 7000,
          }}
          tag="section"
          spaceBetween={0}
          slidesPerView="auto"
          loop={true}
          loopFillGroupWithBlank={true}
        >
          {images.map((item, index) => (
            <SwiperSlide className="big-image" key={index}>
              <img src={`${IMAGE_URL + item.image}`} alt={item.name} />
              {item.name && <p className="text-center mt-1">{item.name}</p>}
            </SwiperSlide>
          ))}
        </Swiper>

        <Swiper
          id="thumbSectionImage"
          className="thum-images"
          spaceBetween={1}
          slidesPerView="auto"
          onSwiper={setrealitySwiper}
          loop={false}
          loopFillGroupWithBlank={true}
          allowTouchMove={false}
        >
          <button
            type="button"
            onClick={onPreviousClick}
            className="left-button"
          >
            <MdKeyboardArrowLeft />
          </button>
          {images.map((item, index) => (
            <SwiperSlide className="font-roboto thum-image" key={index}>
              <img src={`${IMAGE_URL + item.image}`} alt={item.name} />
            </SwiperSlide>
          ))}
          <button type="button" onClick={onNextClick} className="right-button">
            <MdKeyboardArrowRight />
          </button>
        </Swiper>
      </div>
    </>
  );
};

export default PopupSlider;
