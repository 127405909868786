import React from "react";
import Layout from "../components/Layout";
import "./Privacy.scss";

const AboutUs = () => {
  return (
    <Layout
      pageClass="privacy"
      title="ÜBER UNS"
      description="Omas ecommerce online"
      keywords="omas"
      noHeaderBg="true"
    >
      <section className="privacy-policy">
        <div className="container">
          <h1>ÜBER UNS</h1>
          <p>
            Omas-Laden ist ein bäuerlicher Betrieb in Süden des Burgenlandes.{" "}
            <br />
            <br />
            Neben unseren eigenen Produkten geben wir mit diesem Web-Shop
            regionalen Anbietern die Möglichkeit ihre Waren zu vermarkten.
          </p>
          <h2>Wer sind die Verkäufer:</h2>
          <p>
            Die Verkäufer sind Bauern und Privatpersonen die Erzeugnisse von
            ihren Feldern, oder aus deren Gärten anbieten.
            <br />
            Es sind keine industriell gefertigten Massenwaren.
            <br />
            Daher ist die Quantität natürlicher Weise begrenzt und kein
            Marketing Trick.
            <br />
            Da wir uns in der Start-up Phase befinden ist die Palette der
            Angebote noch überschaubar. Jedoch hoffen wir neue Anbieter und
            deren Erzeugnisse zu finden
          </p>
          <h2>BIO?</h2>
          <p>
            Als BIO sind nur jene Produkte ausgezeichnet, dessen Erzeuger das
            BIO Siegel auch tragen darf.Für alle anderen gibt es in der
            Produktinformation den Hinweis, dass keine Chemie verwendet wurde.
            An Hand dessen appellieren wir an ihr Urteilsvermögen ob der „BIO“
            Begriff für sie anwendbar ist, oder nicht.
          </p>
          <h2>Angebotspalette:</h2>
          <p>
            Wir sind erst in der Startphase, somit ist das Angebot noch sehr
            klein. Natürlich hoffen wir auf Zuwachs aus den verschiedensten
            Themengebieten.
          </p>
          <h2>EU-Richtlinien:</h2>
          <p>
            Natürlich versuchen wir die behördlichen Richtlinien einzuhalten.
            Doch sind die Anbieter Privatpersonen, und somit nicht immer am
            letzten Wissensstand. Sofern Sie mit einem Produkt, und seiner
            Etikettierung nicht einverstanden sind, steht es ihnen frei die Ware
            zurückzusenden.
          </p>
          <h2>Cookies/WEB-Shop:</h2>
          <p>
            Wir verwenden keine Cookies oder andere Marketing WEB tools. Diese
            WEB-Seite können Sie unbeobachtet verwenden. Da es keine Möglichkeit
            gab vorgefertigte Shops ohne Cookies oder Tracing zu bekommen wurde
            dieser WEB-Shop neu erstellt. Daher sind zurzeit nur die
            notwendigsten Funktionalitäten verfügbar. Insbesondere das
            Bezahl-Modul. Gemäß der Nutzung werden weitere Funktionalitäten
            angefügt.
          </p>
        </div>
      </section>
    </Layout>
  );
};

export default AboutUs;
