import {
  SHIPPING_METHOD_LIST_REQUEST,
  SHIPPING_METHOD_LIST_SUCCESS,
  SHIPPING_METHOD_LIST_FAIL,
  SHIPPING_METHOD_DETAILS_REQUEST,
  SHIPPING_METHOD_DETAILS_SUCCESS,
  SHIPPING_METHOD_DETAILS_FAIL,
  SHIPPING_METHOD_DELETE_REQUEST,
  SHIPPING_METHOD_DELETE_SUCCESS,
  SHIPPING_METHOD_DELETE_FAIL,
  SHIPPING_METHOD_CREATE_RESET,
  SHIPPING_METHOD_CREATE_FAIL,
  SHIPPING_METHOD_CREATE_SUCCESS,
  SHIPPING_METHOD_CREATE_REQUEST,
  SHIPPING_METHOD_UPDATE_REQUEST,
  SHIPPING_METHOD_UPDATE_SUCCESS,
  SHIPPING_METHOD_UPDATE_FAIL,
  SHIPPING_METHOD_UPDATE_RESET,
  SHIPPING_METHOD_CREATE_REVIEW_REQUEST,
  SHIPPING_METHOD_CREATE_REVIEW_SUCCESS,
  SHIPPING_METHOD_CREATE_REVIEW_FAIL,
  SHIPPING_METHOD_CREATE_REVIEW_RESET,
  SHIPPING_METHOD_TOP_REQUEST,
  SHIPPING_METHOD_TOP_SUCCESS,
  SHIPPING_METHOD_TOP_FAIL,
} from "../constants/shippingMethodConstants";

export const shippingMethodListReducer = (state = { shippingMethods: [] }, action) => {
  switch (action.type) {
    case SHIPPING_METHOD_LIST_REQUEST:
      return { loading: true, shippingMethods: [] };
    case SHIPPING_METHOD_LIST_SUCCESS:
      return {
        loading: false,
        shippingMethods: action.payload.shippingMethods,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case SHIPPING_METHOD_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const shippingMethodDetailsReducer = (
  state = { shippingMethod: { reviews: [] } },
  action
) => {
  switch (action.type) {
    case SHIPPING_METHOD_DETAILS_REQUEST:
      return { ...state, loading: true };
    case SHIPPING_METHOD_DETAILS_SUCCESS:
      return { loading: false, shippingMethod: action.payload };
    case SHIPPING_METHOD_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const shippingMethodDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case SHIPPING_METHOD_DELETE_REQUEST:
      return { loading: true };
    case SHIPPING_METHOD_DELETE_SUCCESS:
      return { loading: false, success: true };
    case SHIPPING_METHOD_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const shippingMethodCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case SHIPPING_METHOD_CREATE_REQUEST:
      return { loading: true };
    case SHIPPING_METHOD_CREATE_SUCCESS:
      return { loading: false, success: true, shippingMethod: {} };
    case SHIPPING_METHOD_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case SHIPPING_METHOD_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const shippingMethodUpdateReducer = (state = { shippingMethod: {} }, action) => {
  switch (action.type) {
    case SHIPPING_METHOD_UPDATE_REQUEST:
      return { loading: true };
    case SHIPPING_METHOD_UPDATE_SUCCESS:
      return { loading: false, success: true, shippingMethod: action.payload };
    case SHIPPING_METHOD_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case SHIPPING_METHOD_UPDATE_RESET:
      return { shippingMethod: {} };
    default:
      return state;
  }
};

export const shippingMethodReviewCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case SHIPPING_METHOD_CREATE_REVIEW_REQUEST:
      return { loading: true };
    case SHIPPING_METHOD_CREATE_REVIEW_SUCCESS:
      return { loading: false, success: true };
    case SHIPPING_METHOD_CREATE_REVIEW_FAIL:
      return { loading: false, error: action.payload };
    case SHIPPING_METHOD_CREATE_REVIEW_RESET:
      return {};
    default:
      return state;
  }
};

export const shippingMethodTopRatedReducer = (state = { shippingMethods: [] }, action) => {
  switch (action.type) {
    case SHIPPING_METHOD_TOP_REQUEST:
      return { loading: true, shippingMethods: [] };
    case SHIPPING_METHOD_TOP_SUCCESS:
      return { loading: false, shippingMethods: action.payload };
    case SHIPPING_METHOD_TOP_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
