const orderStatus = (order) => {
  if (order.status) {
    if (order.isPaid) {
      return "Bezahlt";
    } else if (order.isDelivered) {
      return "Geliefert";
    } else if (order.isSent) {
      return "Gesendet";
    } else if (order.isPacked) {
      return "Verpackt";
    } else if (order.isInvoiced) {
      return "Fakturiert";
    } else {
      return "Bestellt";
    }
  } else {
    return "Abgebrochen";
  }
};
export default orderStatus;
