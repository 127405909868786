export const SELLER_IMAGE_LIST_REQUEST = "SELLER_IMAGE_LIST_REQUEST";
export const SELLER_IMAGE_LIST_SUCCESS = "SELLER_IMAGE_LIST_SUCCESS";
export const SELLER_IMAGE_LIST_FAIL = "SELLER_IMAGE_LIST_FAIL";

export const SELLER_IMAGE_DETAILS_REQUEST = "SELLER_IMAGE_DETAILS_REQUEST";
export const SELLER_IMAGE_DETAILS_SUCCESS = "SELLER_IMAGE_DETAILS_SUCCESS";
export const SELLER_IMAGE_DETAILS_FAIL = "SELLER_IMAGE_DETAILS_FAIL";

export const SELLER_IMAGE_DELETE_REQUEST = "SELLER_IMAGE_DELETE_REQUEST";
export const SELLER_IMAGE_DELETE_SUCCESS = "SELLER_IMAGE_DELETE_SUCCESS";
export const SELLER_IMAGE_DELETE_FAIL = "SELLER_IMAGE_DELETE_FAIL";

export const SELLER_IMAGE_CREATE_REQUEST = "SELLER_IMAGE_CREATE_REQUEST";
export const SELLER_IMAGE_CREATE_SUCCESS = "SELLER_IMAGE_CREATE_SUCCESS";
export const SELLER_IMAGE_CREATE_FAIL = "SELLER_IMAGE_CREATE_FAIL";
export const SELLER_IMAGE_CREATE_RESET = "SELLER_IMAGE_CREATE_RESET";

export const SELLER_IMAGE_UPDATE_REQUEST = "SELLER_IMAGE_UPDATE_REQUEST";
export const SELLER_IMAGE_UPDATE_SUCCESS = "SELLER_IMAGE_UPDATE_SUCCESS";
export const SELLER_IMAGE_UPDATE_FAIL = "SELLER_IMAGE_UPDATE_FAIL";
export const SELLER_IMAGE_UPDATE_RESET = "SELLER_IMAGE_UPDATE_RESET";

export const SELLER_IMAGE_CREATE_REVIEW_REQUEST =
  "SELLER_IMAGE_CREATE_REVIEW_REQUEST";
export const SELLER_IMAGE_CREATE_REVIEW_SUCCESS =
  "SELLER_IMAGE_CREATE_REVIEW_SUCCESS";
export const SELLER_IMAGE_CREATE_REVIEW_FAIL =
  "SELLER_IMAGE_CREATE_REVIEW_FAIL";
export const SELLER_IMAGE_CREATE_REVIEW_RESET =
  "SELLER_IMAGE_CREATE_REVIEW_RESET";

export const SELLER_IMAGE_TOP_REQUEST = "SELLER_IMAGE_TOP_REQUEST";
export const SELLER_IMAGE_TOP_SUCCESS = "SELLER_IMAGE_TOP_SUCCESS";
export const SELLER_IMAGE_TOP_FAIL = "SELLER_IMAGE_TOP_FAIL";
