import React, {useState,useEffect} from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { BiSearch } from "react-icons/bi";
import { IoCart } from "react-icons/io5";
import "./Header.scss";
import { logout } from '../../actions/userActions'
import Nav from "./Nav";

const Header = () => {
  const [scroll, setScroll] = useState(false);

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const logoutHandler = () => {
    dispatch(logout())
  }

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, [])

  return (
    <>
      <header className={scroll ? "sticky" : "standard"}>
        <div className="container">
          <div className="logo">
                <Link to="/" className="logo-image">Omas Laden</Link>
          </div>
          <Nav />
          <div className="other-items">
              <ul className="other-items-ul">
                  {userInfo ? (
                    <>
                    <li className="other-items-list order-1"><Link to="/profile" title={userInfo.name} className="other-items-list-anchor">{userInfo.name}</Link></li>
                    <li className="other-items-list order-2"><Link to="#" onClick={logoutHandler} className="other-items-list-anchor">Logout</Link></li>
                </>
                  ) : (
                  <li className="other-items-list">
                      <Link to="/login" className="other-items-list-anchor">Login</Link>
                  </li>
                  )}
                  {/* <li className="other-items-list">
                      <Link to="#" className="other-items-list-anchor icon"><BiSearch className="search-ic" /></Link>
                      <span className="search-space-marker">
                        <span className="search-bar-container">
                          <input type="text" placeholder="Search" className="input-styles" />
                          <button type="button" className="button-styles"><BiSearch /></button>
                        </span>
                      </span>
                  </li> */}
                  <li className="other-items-list  order-3">
                      <Link to="/cart" className="other-items-list-anchor icon"><IoCart /><span className="count">{cartItems.length}</span></Link>
                  </li>
              </ul>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
