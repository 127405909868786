import React, { useState, useEffect, useMemo } from "react";
import { axiosInstance } from "../utils/axiosInstance";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import CheckoutSteps from "../components/Common/CheckoutSteps";
import { saveShippingAddress } from "../actions/cartActions";
import Layout from "../components/Layout";
import { GoPlus } from "react-icons/go";
import "./Shipping.scss";
import { getUserDetails, updateUserProfile } from "../actions/userActions";
import { USER_UPDATE_PROFILE_RESET } from "../constants/userConstants";
import { calculatePrice, calculateTotalPrice } from "../utils/calculatePrice";
import Message from "../components/Common/Message";

const Shipping = ({ history }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [addresses, setAddresses] = useState();
  const cart = useSelector((state) => state.cart);
  const { shipping } = cart;

  const [shippingMethods, setShippingMethods] = useState();
  const [packingMaterials, setPackingMaterials] = useState();
  const [selectedShippingMethod, setSelectedShippingMethod] = useState();
  const [selectedPackingMaterial, setSelectedPackingMaterial] = useState();
  const [selectedShippingMethodId, setSelectedShippingMethodId] = useState();
  const [selectedPackingMaterialId, setSelectedPackingMaterialId] = useState();
  const [selectedShippingMethodPrice, setSelectedShippingMethodPrice] =
    useState();
  const [selectedPackingMaterialPrice, setSelectedPackingMaterialPrice] =
    useState();

  const [showAddressError, setShowAddressError] = useState(false);
  const [showShippingError, setShowShippingError] = useState(false);
  const [showPackingError, setShowPackingError] = useState(false);
  const [showBillingAddressError, setShowBillingAddressError] = useState(false);

  const [shippingAddressPhone, setShippingAddressPhone] = useState(
    shipping.shippingAddress?.addressPhone
  );
  const [shippingAddressName, setShippingAddressName] = useState(
    shipping.shippingAddress?.addressName
  );
  const [shippingAddress, setShippingAddress] = useState(
    shipping.shippingAddress?.address
  );
  const [shippingCity, setShippingCity] = useState(
    shipping.shippingAddress?.city
  );
  const [shippingPostalCode, setShippingPostalCode] = useState(
    shipping.shippingAddress?.postalCode
  );
  const [shippingCountry, setShippingCountry] = useState(
    shipping.shippingAddress?.country
  );
  const [showAddAddressForm, setShowAddAddressForm] = useState(false);
  const [trigger, setTrigger] = useState(true);
  //billing
  const [billingAddressPhone, setBillingAddressPhone] = useState(
    shipping.billingAddress?.addressPhone
  );
  const [billingAddressName, setBillingAddressName] = useState(
    shipping.billingAddress?.addressName
  );
  const [billingAddress, setBillingAddress] = useState(
    shipping.billingAddress?.address
  );
  const [billingCity, setBillingCity] = useState(shipping.billingAddress?.city);
  const [billingPostalCode, setBillingPostalCode] = useState(
    shipping.billingAddress?.postalCode
  );
  const [billingCountry, setBillingCountry] = useState(
    shipping.billingAddress?.country
  );
  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
  const { success } = userUpdateProfile;

  useEffect(() => {
    if (!userInfo) {
      history.push("/login");
    } else {
      if (!user || !user.name || success) {
        dispatch({ type: USER_UPDATE_PROFILE_RESET });
        dispatch(getUserDetails("profile"));
        defaultAddress();
      } else {
        setName(user.name);
        setEmail(user.email);
        setAddresses(user.addresses);

        if (showAddAddressForm || trigger) {
          setTrigger(false);
          setShippingAddress("");
          setShippingCity("");
          setShippingPostalCode("");
          setShippingCountry("");
          setShippingAddressName("");
          setShippingAddressPhone("");

          setBillingAddress("");
          setBillingCity("");
          setBillingPostalCode("");
          setBillingCountry("");
          setBillingAddressName("");
          setBillingAddressPhone("");
        } else {
          defaultAddress();
        }
        //defaultPackingMethod();
        //defaultShippingMethod();

        return () => {};
      }
    }
  }, [dispatch, history, userInfo, user, success, showAddAddressForm, trigger]);

  const defaultAddress = () => {
    addresses &&
      addresses.map((addr, index) => {
        if (addr.isDefaultShipping) {
          !shippingAddress && setShippingAddress(addr.address);
          !shippingCity && setShippingCity(addr.city);
          !shippingPostalCode && setShippingPostalCode(addr.zip);
          !shippingCountry && setShippingCountry(addr.country);
          !shippingAddressName && setShippingAddressName(addr.name);
          !shippingAddressPhone && setShippingAddressPhone(addr.phone);
        } else if (addr.isDefaultBilling) {
          !billingAddress && setBillingAddress(addr.address);
          !billingCity && setBillingCity(addr.city);
          !billingPostalCode && setBillingPostalCode(addr.zip);
          !billingCountry && setBillingCountry(addr.country);
          !billingAddressName && setBillingAddressName(addr.name);
          !billingAddressPhone && setBillingAddressPhone(addr.phone);
        }
      });
  };

  // const defaultShippingMethod = () => {
  //   shippingMethods &&
  //     shippingMethods.map((shippingMethod) => {
  //       if (shippingMethod.defaultValue) {
  //         !selectedShippingMethod &&
  //           setSelectedShippingMethod(shippingMethod.company);
  //         !selectedShippingMethodId &&
  //           setSelectedShippingMethodId(shippingMethod._id);
  //         !selectedShippingMethodPrice &&
  //           setSelectedShippingMethodPrice(shippingMethod.priceDomestic);
  //       }
  //     });
  //   console.log(selectedShippingMethod);
  // };
  // const defaultPackingMethod = () => {
  //   packingMaterials &&
  //     packingMaterials.map((packingMaterial) => {
  //       if (packingMaterial.defaultValue) {
  //         !selectedPackingMaterial &&
  //           setSelectedPackingMaterial(packingMaterial.material);
  //         !selectedPackingMaterialId &&
  //           setSelectedPackingMaterialId(packingMaterial._id);
  //         !selectedPackingMaterialPrice &&
  //           setSelectedPackingMaterialPrice(packingMaterial.price);
  //       }
  //     });
  //   console.log(selectedPackingMaterial);
  // };
  const submitHandler = (e) => {
    e.preventDefault();
    if (!billingAddressName || !billingAddress) {
      setShowBillingAddressError(true);
    } else if (!shippingAddressName || !shippingAddress) {
      setShowAddressError(true);
    } else if (!selectedShippingMethod) {
      setShowShippingError(true);
    } else if (!selectedPackingMaterial) {
      setShowPackingError(true);
    } else {
      dispatch(
        saveShippingAddress({
          shippingAddress: {
            addressName: shippingAddressName,
            addressPhone: shippingAddressPhone,
            address: shippingAddress,
            city: shippingCity,
            postalCode: shippingPostalCode,
            country: shippingCountry,
          },
          billingAddress: {
            addressName: billingAddressName,
            addressPhone: billingAddressPhone,
            address: billingAddress,
            city: billingCity,
            postalCode: billingPostalCode,
            country: billingCountry,
          },
          selectedPackingMaterial,
          selectedPackingMaterialId,
          selectedShippingMethodPrice,
          selectedShippingMethod,
          selectedShippingMethodId,
          selectedPackingMaterialPrice,
        })
      );
      history.push("/payment");
    }
    setTimeout(() => {
      setShowAddressError(false);
      setShowBillingAddressError(false);
      setShowShippingError(false);
      setShowPackingError(false);
    }, 5000);
  };

  const addAddressHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateUserProfile({
        id: user._id,
        fullAddress: {
          name: shippingAddressName,
          phone: shippingAddressPhone,
          address: shippingAddress,
          city: shippingCity,
          zip: shippingPostalCode,
          country: shippingCountry,
        },
      })
    );
    //defaultAddress();
    setShowAddAddressForm(false);
  };

  useEffect(() => {
    axiosInstance.get("/shipping-methods/all").then((response) => {
      if (response.data) {
        setShippingMethods(response.data.shippingMethods);
      }
    });
    axiosInstance.get("/packing-materials/all").then((response) => {
      if (response.data) {
        setPackingMaterials(response.data.packingMaterials);
      }
    });
  }, []);

  const shippingMethodBlock = (shippingMethod) => {
    // if (shippingMethod.defaultValue) {
    //   !selectedShippingMethod &&
    //     setSelectedShippingMethod(shippingMethod.company);
    //   !selectedShippingMethodId &&
    //     setSelectedShippingMethodId(shippingMethod._id);
    //   !selectedShippingMethodPrice &&
    //     setSelectedShippingMethodPrice(shippingMethod.priceDomestic);
    // }
    return (
      <div key={"dom-" + shippingMethod._id} className="ship-col-1">
        <div className="box-2 common-ship-box">
          <input
            type="radio"
            //defaultChecked={shippingMethod.defaultValue}
            onChange={(e) => {
              setSelectedShippingMethod(shippingMethod.company);
              setSelectedShippingMethodId(shippingMethod._id);
              setSelectedShippingMethodPrice(shippingMethod.priceDomestic);
            }}
            name="shipping-method"
          />
          <div className="address-cover">
            <h4>
              {shippingMethod.company}
              <small>({calculatePrice(shippingMethod.priceDomestic)})</small>
            </h4>
            <p>{shippingMethod.description}</p>
          </div>
        </div>
      </div>
    );
  };
  const shippingMethodBlock2 = (shippingMethod) => {
    return (
      <div key={"int-" + shippingMethod._id} className="ship-col-1">
        <div className="box-2 common-ship-box">
          <input
            type="radio"
            //defaultChecked={shippingMethod.defaultValue}
            onChange={(e) => {
              setSelectedShippingMethod(shippingMethod.company);
              setSelectedShippingMethodId(shippingMethod._id);
              setSelectedShippingMethodPrice(shippingMethod.priceEu);
            }}
            name="shipping-method"
          />
          <div className="address-cover">
            <h4>
              {shippingMethod.company}
              <small>({calculatePrice(shippingMethod.priceEu)})</small>
            </h4>
            <p>{shippingMethod.description}</p>
          </div>
        </div>
      </div>
    );
  };
  const packingMethodBlock = (packingMaterial) => {
    // if (packingMaterial.defaultValue) {
    //   !selectedPackingMaterial &&
    //     setSelectedPackingMaterial(packingMaterial.material);
    //   !selectedPackingMaterialId &&
    //     setSelectedPackingMaterialId(packingMaterial._id);
    //   !selectedPackingMaterialPrice &&
    //     setSelectedPackingMaterialPrice(packingMaterial.price);
    // }
    return (
      <div key={packingMaterial._id} className="ship-col">
        <div className="box-2 common-ship-box">
          <input
            type="radio"
            //defaultChecked={packingMaterial.defaultValue}
            onChange={(e) => {
              setSelectedPackingMaterial(packingMaterial.material);
              setSelectedPackingMaterialId(packingMaterial._id);
              setSelectedPackingMaterialPrice(packingMaterial.price);
            }}
            name="packing"
          />
          <div className="address-cover">
            <h4>
              {packingMaterial.material}
              <small>({calculatePrice(packingMaterial.price)})</small>
            </h4>
            <p>{packingMaterial.description}</p>
          </div>
        </div>
      </div>
    );
  };

  const shippingAddressBlock = (addr, index) => {
    if (addr.isDefaultShipping) {
      !shippingAddress && addr.address && setShippingAddress(addr.address);
      !shippingCity && addr.city && setShippingCity(addr.city);
      !shippingPostalCode && addr.zip && setShippingPostalCode(addr.zip);
      !shippingCountry && addr.country && setShippingCountry(addr.country);
      !shippingAddressName && addr.name && setShippingAddressName(addr.name);
      !shippingAddressPhone &&
        addr.phone &&
        setShippingAddressPhone(addr.phone);
    }
    return (
      <div key={`shiping-address-${index}`} className="ship-col">
        <div className="box-2 common-ship-box">
          <input
            type="radio"
            onClick={(e) => {
              setShowAddAddressForm(false);
              setShippingAddress(addr.address);
              setShippingCity(addr.city);
              setShippingPostalCode(addr.zip);
              setShippingCountry(addr.country);
              setShippingAddressName(addr.name);
              setShippingAddressPhone(addr.phone);
            }}
            name="shipping-address"
            defaultChecked={addr.isDefaultShipping}
          />
          <div className="address-cover">
            <h4>{addr.name}</h4>
            <ul>
              {addr.address && <li>{addr.address}</li>}
              {addr.city && <li>{addr.city}</li>}
              {addr.zip && <li>{addr.zip}</li>}
              {addr.country && <li>{addr.country}</li>}
              {addr.phone && <li>{addr.phone}</li>}
            </ul>
          </div>
        </div>
      </div>
    );
  };

  const billingAddressBlock = (addr, index) => {
    if (addr.isDefaultBilling) {
      !billingAddress && addr.address && setBillingAddress(addr.address);
      !billingCity && addr.city && setBillingCity(addr.city);
      !billingPostalCode && addr.zip && setBillingPostalCode(addr.zip);
      !billingCountry && addr.country && setBillingCountry(addr.country);
      !billingAddressName && addr.name && setBillingAddressName(addr.name);
      !billingAddressPhone && addr.phone && setBillingAddressPhone(addr.phone);
    }
    return (
      <div key={`billing-address-${index}`} className="ship-col">
        <div className="box-2 common-ship-box">
          <input
            type="radio"
            onClick={(e) => {
              setShowAddAddressForm(false);
              setBillingAddress(addr.address);
              setBillingCity(addr.city);
              setBillingPostalCode(addr.zip);
              setBillingCountry(addr.country);
              setBillingAddressName(addr.name);
              setBillingAddressPhone(addr.phone);
            }}
            name="billing-address"
            defaultChecked={addr.isDefaultBilling}
          />
          <div className="address-cover">
            <h4>{addr.name}</h4>
            <ul>
              {addr.address && <li>{addr.address}</li>}
              {addr.city && <li>{addr.city}</li>}
              {addr.zip && <li>{addr.zip}</li>}
              {addr.country && <li>{addr.country}</li>}
              {addr.phone && <li>{addr.phone}</li>}
            </ul>
          </div>
        </div>
      </div>
    );
  };

  function findPos(obj) {
    var curtop = 0;
    if (obj.offsetParent) {
      do {
        curtop += obj.offsetTop - 100;
      } while ((obj = obj.offsetParent));
      return [curtop];
    }
  }

  const goToForm = (e) => {
    setShowAddAddressForm(true);
    setTimeout(() => {
      window.scroll(0, findPos(document.getElementById("goToForm")));
    }, 1000);
  };

  return (
    <Layout
      pageClass="products"
      title="Shipping & Packing"
      description="Omas ecommerce online"
      keywords="omas"
      noHeaderBg="true"
    >
      <section className="without-hero-sections title-adjustments">
        <Container>
          <CheckoutSteps step2 current="shipping" />
          <Row className="mb-2">
            {showShippingError && (
              <Col md={11}>
                <Message variant="danger">
                  Please select a shipping method.
                </Message>
              </Col>
            )}
            <Col md={6}>
              <h2>
                VERSANDPARTNER <span>(Österreich)</span>
              </h2>
              <div className="ship-boxes">
                {shippingMethods &&
                  shippingMethods.map((shippingMethod) =>
                    shippingMethodBlock(shippingMethod)
                  )}
              </div>
            </Col>
            <Col md={6}>
              <h2>
                VERSANDPARTNER <span>(ausserhalb Österreich)</span>
              </h2>
              <div className="ship-boxes">
                {shippingMethods &&
                  shippingMethods.map((shippingMethod) =>
                    shippingMethodBlock2(shippingMethod)
                  )}
              </div>
            </Col>
          </Row>
          <h3>Verpackung + Füllmaterial</h3>
          <Row className="mb-2">
            {showPackingError && (
              <Col md={11}>
                <Message variant="danger">
                  Bitte wählen sie das Füllmarterial
                </Message>
              </Col>
            )}
            <div className="ship-boxes">
              {packingMaterials &&
                packingMaterials.map((packingMaterial) =>
                  packingMethodBlock(packingMaterial)
                )}
            </div>
          </Row>
          <h3>Rechnungsadresse</h3>
          <Row className="mb-2">
            {showBillingAddressError && (
              <Col md={11}>
                <Message variant="danger">
                  Bitte wählen Sie eine Adresse aus.
                </Message>
              </Col>
            )}
            <div className="ship-boxes">
              <div className="ship-col">
                <div
                  onClick={(e) => goToForm(e)}
                  className="box-1 common-ship-box"
                >
                  <GoPlus />
                  <h3>Adresse hinzufügen</h3>
                </div>
              </div>
              {addresses &&
                addresses.map((address, index) =>
                  billingAddressBlock(address, index)
                )}
            </div>
          </Row>
          <h3>Lieferadresse</h3>
          <Row className="mb-2">
            {showAddressError && (
              <Col md={11}>
                <Message variant="danger">
                  Bitte wählen Sie eine Adresse aus.
                </Message>
              </Col>
            )}
            <div className="ship-boxes">
              <div className="ship-col">
                <div
                  onClick={(e) => goToForm(e)}
                  className="box-1 common-ship-box"
                >
                  <GoPlus />
                  <h3>Adresse hinzufügen</h3>
                </div>
              </div>
              {addresses &&
                addresses.map((address, index) =>
                  shippingAddressBlock(address, index)
                )}
            </div>
          </Row>
          {showAddAddressForm && (
            <Form onSubmit={addAddressHandler} id="goToForm">
              <Form.Group controlId="address_name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Name eingeben"
                  value={shippingAddressName}
                  required
                  onChange={(e) => setShippingAddressName(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId="address_phone">
                <Form.Label>Telefon</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Telefon eingeben"
                  value={shippingAddressPhone}
                  required
                  onChange={(e) => setShippingAddressPhone(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId="address">
                <Form.Label>Adresse</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Adresse eingeben"
                  value={shippingAddress}
                  required
                  onChange={(e) => setShippingAddress(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId="city">
                <Form.Label>Ort</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Stadt betreten"
                  value={shippingCity}
                  required
                  onChange={(e) => setShippingCity(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId="postalCode">
                <Form.Label>Postleitzahl</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Postleitzahl eingeben"
                  value={shippingPostalCode}
                  required
                  onChange={(e) => setShippingPostalCode(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId="country">
                <Form.Label>Land</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Land eingeben"
                  value={shippingCountry}
                  required
                  onChange={(e) => setShippingCountry(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Button type="submit" variant="primary">
                Adresse speichern
              </Button>
              <Button
                type="reset"
                onClick={(e) => setShowAddAddressForm(false)}
                variant="secondary"
              >
                Abbrechen
              </Button>
            </Form>
          )}
          <Row className="my-2">
            <div className="ship-boxes">
              <div className="ship-col">
                <Button
                  type="submit"
                  onClick={(e) => submitHandler(e)}
                  variant="primary"
                >
                  Weiter
                </Button>
              </div>
            </div>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default Shipping;
