import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../utils/axiosInstance";
import { MdKeyboardArrowUp } from "react-icons/md";
import "./Footer.scss";
import Popup from "../Common/Popup";

const Footer = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [contactPopup, setContactPopup] = useState(false);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [requestType, setRequestType] = useState("Kontakt");
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState(0);
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const fetchCategory = async () => {
    setLoading(true);
    const res = await axiosInstance.get(`/categories`);
    setCategories(res.data.categories);
    setLoading(false);
  };

  useEffect(() => {
    fetchCategory();
    if (userInfo) {
      setFullName(userInfo.name);
      setEmail(userInfo.email);
    }
    if (!contactPopup) {
      setAlert(0);
    }
  }, [contactPopup]);
  useEffect(() => {
    let interval;
    if (alert == 2) {
      interval = setInterval(() => {
        setContactPopup(false);
      }, 2000);
    }
    return () => clearInterval(interval);
  }, [alert]);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setAlert(1);
    try {
      const data = JSON.stringify({
        fullName: fullName,
        email: email,
        subject: subject,
        message: message,
        requestType: requestType,
      });
      const res = await axiosInstance.post("/contacts", data);
      if (res.status === 201) {
        setFullName("");
        setEmail("");
        setSubject("");
        setMessage("");
        setAlert(2);
      } else {
        setAlert(3);
      }
    } catch (err) {
      setAlert(3);
    }
  };
  window.addEventListener("scroll", toggleVisible);
  let showAlert = "";
  if (alert == 1) {
    showAlert = <p>Submitting ...</p>;
  } else if (alert == 2) {
    showAlert = (
      <div role="alert" class="fade alert alert-success show">
        Danke uns zu kontaktieren
      </div>
    );
  } else if (alert == 3) {
    showAlert = (
      <div role="alert" class="fade alert alert-danger show">
        Some error occurred !
      </div>
    );
  } else {
    showAlert = "";
  }
  return (
    <>
      <footer className="footer">
        <section className="footer-top">
          <div className="container">
            <div className="omas-brief">
              <h4 className="logo-footer">Omas Laden</h4>
              <p className="foot-description">
                Omas-Laden ist ein bäuerlicher Betrieb in Süden des
                Burgenlandes. Neben unseren eigenen Produkten geben wir mit
                diesem Web-Shop regionalen Anbietern die Möglichkeit ihre Waren
                zu vermarkten.
              </p>
            </div>
            <div className="menu-items">
              <h5 className="menu-title">Information</h5>
              <ul>
                <li className="menu-list">
                  <Link to="/" className="menu-items-link">
                    Home
                  </Link>
                </li>
                <li className="menu-list">
                  <Link to="/products" className="menu-items-link">
                    Produkte
                  </Link>
                </li>
                <li className="menu-list">
                  <Link to="/sellers" className="menu-items-link">
                    Verkäufer
                  </Link>
                </li>
                <li className="menu-list">
                  <Link to="/new" className="menu-items-link">
                    Neuheiten
                  </Link>
                </li>
                <li className="menu-list">
                  <Link
                    to="#"
                    onClick={() => setContactPopup(true)}
                    className="menu-items-link"
                  >
                    Kontakt
                  </Link>
                </li>
                <li className="menu-list">
                  <Link to="#" className="menu-items-link">
                    Verkäufer werden
                  </Link>
                </li>
              </ul>
            </div>
            <div className="menu-items">
              <h5 className="menu-title">PRODUKTE</h5>
              <ul>
                {!loading &&
                  categories &&
                  categories.map((item, index) => (
                    <li
                      key={`footer-product-${item._id}`}
                      className="menu-list"
                    >
                      <Link
                        to={`/category/${item._id}`}
                        className="menu-items-link"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
            <div className="menu-items">
              <h5 className="menu-title">Konto</h5>
              <ul>
                <li className="menu-list">
                  <Link to="/profile" className="menu-items-link">
                    Mein Konto
                  </Link>
                </li>
                <li className="menu-list">
                  <Link to="/cart" className="menu-items-link">
                    Meine Bestellungen
                  </Link>
                </li>
              </ul>
            </div>
            <div className="menu-items">
              <h5 className="menu-title">Information</h5>
              <ul>
                <li className="menu-list">
                  <Link to="/data-protection" className="menu-items-link">
                    Datenschutzerklärung
                  </Link>
                </li>
                <li className="menu-list">
                  <Link to="/disclaimer" className="menu-items-link">
                    Haftungsaussluss
                  </Link>
                </li>

                <li className="menu-list">
                  <Link to="/imprint" className="menu-items-link">
                    Impressum
                  </Link>
                </li>
                <li className="menu-list">
                  <Link to="/about-us" className="menu-items-link">
                    Über uns
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section className="footer-bottom">
          <div className="container">
            <p className="footer-copy-right">
              © {new Date().getFullYear()} Omas Laden. All Rights Reserved.
            </p>
            <Link
              to="#"
              className="back-to-top"
              onClick={scrollToTop}
              style={{ display: visible ? "inline" : "none" }}
            >
              <MdKeyboardArrowUp />
            </Link>
          </div>
        </section>

        <div className="form-popup">
          <Popup trigger={contactPopup} setTrigger={setContactPopup}>
            <h4 className="slider-title">Kontakt zu Omas-Laden:</h4>
            <p>
              E-mail: <a href="mailto:mail@omas-laden.at">mail@omas-laden.at</a>
            </p>
            <p>
              Telefon: <a href="tel: +43 699 14107 207"> +43 699 14107 207 </a>
            </p>
            {showAlert}
            <h5>
              <span>Oder: On-Line Formular</span>
            </h5>
            <form onSubmit={handleSubmit}>
              <label>User Name</label>
              {userInfo ? (
                <p>{userInfo.name}</p>
              ) : (
                <input
                  type="text"
                  value={fullName}
                  placeholder=""
                  onChange={(e) => setFullName(e.target.value)}
                />
              )}
              <label>Email</label>
              {userInfo ? (
                <p>{userInfo.email}</p>
              ) : (
                <input
                  type="text"
                  value={email}
                  placeholder=""
                  onChange={(e) => setEmail(e.target.value)}
                />
              )}
              <label>Betreff</label>
              <input
                type="text"
                value={subject}
                placeholder=""
                onChange={(e) => setSubject(e.target.value)}
              />
              <label>Nachricht</label>
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <button type="submit" className="submit_btn">
                Absenden
              </button>
              <a
                type="button"
                className="cancel_btn"
                onClick={() => setContactPopup(false)}
              >
                Abbrechen
              </a>
            </form>
          </Popup>
        </div>
      </footer>
    </>
  );
};

export default Footer;
