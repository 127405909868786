import React from "react";
import {Link} from "react-router-dom";
import Checkbox from "../components/Common/Checkbox";
import "./Checkout.scss";

const Checkout = () => {
  return (
    <>
      <section className="checkout-page">
        <div className="container">
          <div className="form-colum">
            <h1 className="form-colum-title">Spices</h1>
            <div className="form-colum-breadcrumb">
                <ul>
                    <li><Link to="#">Cart</Link></li>
                    <li><span>Information</span></li>
                    <li><span>Shipping</span></li>
                    <li><span>Payment</span></li>
                </ul>
            </div>
            <div className="contact-information">
                <h2 className="contact-information-title">Contact information</h2>
                <h2 className="contact-information-login">Already have an account? <Link to="#">Log in</Link></h2>
            </div>
            <div className="contact-information-inputs">
                <input type="email" placeholder="Email" className="input-field" />
                <Checkbox id="check1" label="Keep me up to date on news and offers" />
            </div>

            <h4 className="shipping-address-title">Shipping Address</h4>
            <div className="shipping-address-form">
                <div className="dual-colum">
                    <div className="input-wrapper">
                        <input type="text" className="input-field" placeholder="First name (optional)" />
                    </div>
                    <div className="input-wrapper">
                        <input type="text" className="input-field" placeholder="Last name" />
                    </div>
                </div>
                <div className="one-colum">
                  <input type="text" className="input-field" placeholder="Company (optional)" />
                </div>
                <div className="one-colum">
                  <input type="text" className="input-field" placeholder="Address" />
                </div>
                <div className="one-colum">
                  <input type="text" className="input-field" placeholder="Apartment,suite,etc. (optional)" />
                </div>
                <div className="three-colum">
                    <div className="input-wrapper">
                        <select className="input-field">
                          <option>Country/Region</option>
                        </select>
                    </div>
                    <div className="input-wrapper">
                        <select className="input-field">
                          <option>State</option>
                        </select>
                    </div>
                    <div className="input-wrapper">
                        <input type="text" className="input-field" placeholder="PIN code" />
                    </div>
                </div>

                <div className="one-colum">
                  <input type="text" className="input-field" placeholder="Phone (optional)" />
                </div>
                <div className="one-colum">
                  <Checkbox id="check2" label="Save this information for next time" />
                </div>
                <div className="btn-group-checkout">
                  <button type="button" className="checkout-shopping">Continue to shipping</button>
                  <Link to="#">Return to cart</Link>
                </div>

                <div className="all-right-reserved">
                  <p>All rights reserved Spices</p>
                </div>
            </div>
          </div>
          <div className="items-colum">
            <ul>
              <li className="single-item">
                <span className="item-detail">
                  <span className="image-sec">
                    <i>1</i>
                    <img src="/assets/images/loose.png" alt="img" />
                  </span>
                  <span className="item-text">
                    Candied Fancy Nectarines
                  </span>
                </span>
                <span className="item-price">$6.20</span>
              </li>

              <li className="single-item">
                <span className="item-detail">
                  <span className="image-sec">
                    <i>1</i>
                    <img src="/assets/images/loose.png" alt="img" />
                  </span>
                  <span className="item-text">
                    Candied Fancy Nectarines
                  </span>
                </span>
                <span className="item-price">$6.20</span>
              </li>
            </ul>

            <div className="sub-section">
              <h5 className="sub-section-title">Subtotal <span className="sub-price"><b>$8.60</b></span></h5>
              <h5 className="sub-section-title">shipping <span className="sub-price">Calculated at next step</span></h5>
            </div>

            <h5 className="checkout-total">Total <span>$8.60</span></h5>
          </div>
        </div>
      </section>
    </>
  );
};

export default Checkout;
